// CustomLayerGroup.js
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Rectangle, LayerGroup, FeatureGroup, Popup } from 'react-leaflet';
import { CustomLayers } from './CustomLayers';
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet';
const CustomLayerGroup = ({ layer }) => { // position, unlocked = false,color, onDragEnd,
  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);
  const [items, setItems] = useState([]);
  useEffect(() => {

    setItems(project.items
      .filter(item => item.itParentId === layer.itId))
  }, [project?.items]);
  const calculateBounds = (items) => {
    if (!items || items.length === 0) {
      return null;
    }
    const latLngs = items.map(item => {
      return item.itGeometry;
    });
    const bounds = L.latLngBounds(latLngs);
    return bounds;
  };
  const bounds = calculateBounds(items);
  const handleFeatureGroupClick = (event) => {
    event.originalEvent.stopPropagation()
    L.DomEvent.stopPropagation(event);
    //    alert('FeatureGroup clicked', event);
  };
  return (
    userPreferences.projects?.[project.projId]?.visibleLayersId?.includes(layer.itId) &&
    <FeatureGroup key={layer.itId} eventHandlers={{ click: handleFeatureGroupClick }}>

      {bounds && layer.itFeatures.color != '' && <Rectangle key={`${JSON.stringify(layer.itFeatures)}]`} bounds={bounds} color={layer.itFeatures.color} fillOpacity={parseFloat(layer.itFeatures.opacity)} />}
      {items.map(item => {
        if (item.itType == 'tree' && !item.match_filters) {
          return null; // Si match_filter n'est pas true, n'affiche rien pour cet item
        }
        const CustomLayer = CustomLayers[item.itType];
        return CustomLayer ? <CustomLayer item={item} key={item.itId} /> : null;
      })}
    </FeatureGroup>);
};
export default CustomLayerGroup;
































