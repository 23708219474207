import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import API from './../../../api';
import { Alert } from 'react-bootstrap';
import ProgressBar from './../ProgressBar/ProgressBar';
import { BsPlusCircleDotted } from 'react-icons/bs';

// import { v4 as uuidv4 } from 'uuid';
import './uploader.css';
import { } from 'react-bootstrap';
const Uploader = ({ profile, rootProps = {}, size = 'small', onUploadStart = null, onUploadSuccess = null, onUploadFailure = null }) => {
  const config = useSelector((state) => state.config);
  const uploads = {};
  const [currentFiles, setCurrentFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [progressBarProgress, setProgressBarProgress] = useState(0);
  const [uploaderInfos, setUploaderInfos] = useState('');
  const [inputProps, setInputProps] = useState({ maxSize: 0, accept: '' })
  const dropzoneRef = useRef(null);
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    setRejectedFiles(rejectedFiles);
    try {
      setCurrentFiles([...currentFiles, ...acceptedFiles]);
      acceptedFiles.forEach((file) => {
        file.upload_id = new Date().getTime();
        uploads[file.upload_id] = {
          size: file.size,
          loaded: 0
        }
        if (onUploadStart) {
          onUploadStart(file);
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('profile', profile);
        API.post('/upload', formData, {
          onUploadProgress: ({ loaded, total }) => {
            if (uploads[file.upload_id]) {
              uploads[file.upload_id].loaded = loaded;
              setProgressBarProgress(calculateTotalProgress())
            }
          },
        })
          .then((response) => {

            delete uploads[file.upload_id];
            if (onUploadSuccess) {
              onUploadSuccess({ ...file, ...response.data });
            }
          })
          .catch((error) => {
            if (onUploadFailure) {
              onUploadFailure(file);
            }
            console.error('Erreur lors de l\'envoi :', error);
          });
      });
    } finally {
    }
  };
  useEffect(() => {

    if (!config.upload_profiles?.[profile]) {
      return;
    }
    const inputProps = config.upload_profiles[profile];
    setInputProps(inputProps);
    const extensions = inputProps.accept?.split(', ').map(type => {
      switch (type) {
        case 'image/jpeg':
          return 'JPG';
        case 'image/png':
          return 'PNG';
        case 'application/pdf':
          return 'PDF';
        default:
          return '';
      }
    }).filter(ext => ext).join(', ');
    const tailleMaxMo = inputProps?.maxSize / (1024 * 1024);

    setUploaderInfos(`(${extensions} • ${tailleMaxMo}Mo max par fichier)`)
  }, [profile])
  const calculateTotalProgress = () => {
    let totalSize = 0;
    let totalLoaded = 0;
    for (const k in uploads) {
      totalSize += uploads[k].size;
      totalLoaded += uploads[k].loaded;
    }
    return 100 * totalLoaded / totalSize;
  };
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxSize: 10 * 1024 * 1024,
    noClick: true,
    ...inputProps,
  });
  const getCombinedInputProps = () => {
    return { ...getInputProps(), ...inputProps, multiple: inputProps.multiple }
  }
  return (
    <div className='uploader' tabIndex={-1}>
      {inputProps.accept != '' && <div {...getRootProps()} className={`drop-zone ${size}`} >
        <input {...getCombinedInputProps()} />
        {size === 'large' ? (
          isDragActive ? (
            <div>Déposez les fichiers ici...</div>
          ) : (
            <div className='border rounded inner'>

              <p className='uploader-infos'>
                Glissez-déposez des fichiers ici
                <br />
                <small>{uploaderInfos}</small>
              </p>
              <p><button type="button" onClick={open} className='btn-upload'><BsPlusCircleDotted /></button></p>
            </div>
          )
        ) : size === 'small' ? (
          <button className='btn-upload' type="button" onClick={open}  ><BsPlusCircleDotted /></button>
        ) : null}
      </div>}
      {
        rejectedFiles.length > 0 && (
          <Alert variant="warning" className='text-start mt-3'>
            <button type="button" className="btn-close btn-xs" onClick={() => setRejectedFiles([])}></button>
            <ul className='mb-0 pt-0 pb-0'>
              {rejectedFiles.map((item, index) => (
                <li key={index}>
                  <small>
                    Le fichier "{item.file.path}" est trop volumineux.
                  </small>
                </li>
              ))}
            </ul>
          </Alert>
        )
      }
      {
        progressBarProgress > 0 && progressBarProgress < 100 &&
        <ProgressBar progress={progressBarProgress} />
      }
    </div >
  );
};
export default Uploader;
