import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header_User from './../user/Header_User';

import { isDev } from './../../utils';
import { fetchUserDetails } from './../../redux/userSlice';
import './header.css';
import './frontoffice-header.css';
import API from './../../api';
import ProjectHeaderMenuItem from './../projects/menus/ProjectHeaderMenuItem';

const HeaderMain = React.memo(() => {
  const [expanded, setExpanded] = useState(false); // Ajouté pour gérer l'état d'expansion de Navbar
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, []);

  return (
    <header className="App-header header-main" id="header">
      <div id="header-main">
        <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
          <Container fluid>
            <Navbar.Brand href="/" className='px-4 m-0 logo' onClick={() => setExpanded(false)}><img src='/img/logo-header.png' alt='IF' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <ProjectHeaderMenuItem onClick={() => setExpanded(false)} />
                {/* Assurez-vous que ProjectHeaderMenuItem gère l'événement onClick si c'est un composant personnalisé */}
                <Nav.Link></Nav.Link>
                <Nav.Link href="#link">
                </Nav.Link>
              </Nav>
              <Nav className="ms-auto">
                <Header_User />
                <Nav.Link href="/user/home" onClick={() => setExpanded(false)}>Profil</Nav.Link>
                {/* Ajoute d'autres éléments ici si nécessaire, n'oubliez pas de gérer onClick */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header >
  );
});
export default React.memo(HeaderMain);
