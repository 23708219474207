import React, { useState, useEffect, useRef } from 'react'
import { Card, Form } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import './../../../../css/cards.css';
import { HtmlElement } from './../../../helpers/HtmlElements/HtmlElements';
import { debug, swalInput, removeAccents, toCamelCase } from './../../../../utils';
import LeafCheckbox from './../../../helpers/LeafCheckbox/LeafCheckbox';
function ProjectMeta({ meta, onChange }) {

  const addMeta = async (idxc) => {
    const response = await swalInput({ title: 'Nom de la nouvelle caractéristique :', value: "" })
    if (response) {
      const metaCopy = cloneDeep(meta);
      metaCopy.tree[idxc].items.push({
        itId: toCamelCase(removeAccents(response.replace(/[^0-9a-z]/i, ''))),
        itType: 'input',
        itActive: true,
        itName: response.trim()
      });
      onChange(metaCopy);
    }
  }
  const updateMeta = (event, idxc, idxi) => {
    console.clear()
    const { name, value } = event.target;
    console.log(name, value, idxc, idxi);
    const metaCopy = cloneDeep(meta);
    if (typeof idxi == 'undefined') {
      metaCopy.tree[idxc][name] = !metaCopy.tree[idxc][name];
    } else {
      metaCopy.tree[idxc].items[idxi][name] = !metaCopy.tree[idxc].items[idxi][name];

      //  metaCopy.tree[parseInt(parts[1])].items[parseInt(parts[2])][parts[0]] = !metaCopy.tree[parseInt(parts[1])].items[parseInt(parts[2])][parts[0]]
    }
    onChange(metaCopy);
  }
  useEffect(() => { }, [meta]);
  return (
    <div>
      <div className='row'>
        {meta?.tree.map((cat, idxc) => (
          <div className='col-md-4 mb-4' key={idxc}>
            <Card>
              <Card.Body>
                <Card.Title className={``}>
                  <label className='d-flex justify-content-between w-100'>
                    <HtmlElement.checkbox
                      value={cat.active}
                      name={`active`}
                      label={cat.name}
                      onChange={(e) => updateMeta(e, idxc)}
                      checked={cat.active}
                    />
                  </label>
                </Card.Title>
                <Card.Text>
                  {cat.items.map((item, idxi) =>
                  (<div key={idxi} className='d-block'>
                    <HtmlElement.checkbox
                      value={item.active}
                      name={`active`}
                      label={item.name}
                      disabled={cat.active == false}
                      checked={item.active}
                      onChange={(e) => updateMeta(e, idxc, idxi)}
                    />
                  </div>
                  ))}
                  <div className='pt-1'>
                    <span onClick={e => addMeta(idxc)} className='fst-italic underline-on-hover small' >Ajouter une caractéristique</span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      {debug(meta?.tree, 0)}
    </div>
  );
}

export default ProjectMeta;
/*
                    <LeafCheckbox
                      className='d-flex justify-content-between flex-row-reverse w-100'
                      inputClassName=''
                      type='checkbox'
                      value={cat.catActive}
                      name={`catActive-${idxc}`}
                      checked={cat.catActive}
                      label={cat.catName}
                      onChange={(e) => updateMeta(e, idxc)}
                    />

                    <LeafCheckbox
                      type='checkbox'
                      value={item.itActive}
                      className='d-flex justify-content-between flex-row-reverse w-100'
                      inputClassName=''
                      name={`itActive-${idxc}-${idxi}`}
                      checked={item.itActive}
                      label={item.itName}
                      onChange={(e) => updateMeta(e, idxi)}
                      disabled={cat.catActive == false}
                    />


                    */
