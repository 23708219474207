import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { debug } from './../../utils';
import { fetchUserDetails } from './../../redux/userSlice';
import ProjectsHomeBloc from './../projects/ProjectsHomeBloc';
import ProjectsAdminHomeBloc from './../projects/ProjectsAdminHomeBloc';
import './home-main.css';
const HomeMain = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [components, setComponents] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const componentMap = {
    'Projects/user': ProjectsHomeBloc,
    'Projects/admin': [ProjectsHomeBloc, ProjectsAdminHomeBloc],
    // Admin:ProjectsHomeBloc ? [ProjectsHomeBloc, ProjectsHomeBloc] : null,
    // Admin: AdminHomeBloc ? AdminHomeBloc : null,
    // Customer: CustomerHomeBloc ? CustomerHomeBloc : null,
  };
  useEffect(() => {
    dispatch(fetchUserDetails());
  }, []
  );
  useEffect(() => {
    const loadedComponents = [];
    if (user?.userRoles && !initialized) {
      Object.keys(user.userRoles).forEach(role => {
        const key = `${role}/${user.userRoles[role]}`;
        if (componentMap[key]) {
          if (Array.isArray(componentMap[key])) {
            componentMap[key].forEach(component => {
              loadedComponents.push(component);
            });
          } else {
            loadedComponents.push(componentMap[key]);
          }
        }
      });
      setComponents(loadedComponents);
      setInitialized(true);
    }
  }, [user?.userRoles, initialized]);
  return (
    <div id="home-main">
      {(user && user.details) &&
        <div className="container mt-4">
          <div className="row">
            {components.map((Component, index) => (
              <Component key={index} />
            ))}
          </div>
          <div className="row mt-3  mb-5">
          </div>
        </div>
      }
      {debug(user)}
    </div>
  )
};
export default HomeMain;
/*
            }
            <div className="col-md-4  mb-5">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Mon compte</h5>
                  <p className="card-text">Gérer mon compte utilisateur.</p>
                  <a href="#" className="btn btn-primary">Accéder à mon compte</a>
                </div>
              </div>
            </div>
            <div className="col-md-4  mb-5">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Actions à venir</h5>
                  <p className="card-text">Liste des prochaines actions planifiées.</p>
                  <a href="#" className="btn btn-primary">Voir les actions</a>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Agenda</h5>
                  <p className="card-text">Mon agenda personnel.</p>
                  <a href="#" className="btn btn-primary">Ouvrir l'agenda</a>
                </div>
              </div>
            </div>*/
