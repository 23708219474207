import React, { useState, useEffect, useRef } from 'react';
import { debug } from 'utils';
import { HtmlElement } from './../../../helpers/HtmlElements/HtmlElements';
import { useProjectMapContext } from './../../hooks/useProjectMapContext'
const ProjectParams = ({ project, onChange }) => {
  const { mapRef } = useProjectMapContext();
  useEffect(() => { }, []);
  return (
    <div>
      <div className='row'>
        <div className='col-md-6 mb-3'>
          <HtmlElement.formGroup
            value={project.projName}
            name='projName'
            label='Nom : '
            onChange={onChange}
            className='mb-2'
            inputClassName='form-control-lg'
          />
          <HtmlElement.formGroup
            value={project.projCity.cityPostCode + ' ' + project.projCity.cityName}
            name='projCity'
            label='Ville : '
            readOnly="readOnly"
            onChange={onChange}
            className='mb-2'
            inputClassName='form-control-sm'
          />
          <div className='form-group mb-2'>
            <label className='form-group-label'>Centre du projet</label>
            <div className='input-group'>
              <HtmlElement.input
                name="projGeometry"
                value={project.projGeometry}
                readOnly={true}
              />
              <button
                className='btn btn-sm btn-outline-primary'
                onClick={e => {
                  const centre = mapRef.current.getCenter();
                  onChange({ projGeometry: [centre.lat, centre.lng] });
                }}
              >
                Définir au centre de la carte
              </button>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <HtmlElement.formGroup
            type="textarea"
            value={project.projMemo}
            name='projMemo'
            label='Notes : '
            style={{ height: '200px' }}
            onChange={onChange}
            inputClassName='form-control-lg'
          />
        </div>
      </div>
      {debug(project, 1)}
    </div>
  );
}

export default ProjectParams;


/*


*/