import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function ProjectHeaderMenuItem({ onClick }) {
  const user = useSelector(state => state.user);
  const canCreateProject = () => {
    return user.userRoles?.Projects == 'admin' &&
      (user.details?.projects.length < user.details?.customer?.plan.planMaxProjects)
  }
  return (
    user.userRoles?.Projects &&
    <NavDropdown title={`Projets (${user.details?.projects.length})`} id="basic-nav-dropdown">
      {canCreateProject() &&
        <>
          <NavDropdown.Item as={Link} to="/project/map/new">Créer un projet</NavDropdown.Item>
          <NavDropdown.Divider />
        </>
      }
      {user.details?.projects.length > 0 &&
        user.details?.projects.map((project, idx) => (
          <NavDropdown.Item key={idx} as={Link} to={`/project/map/${project.projId}`} onClick={onClick}>
            {project.projName}
          </NavDropdown.Item>
        ))
      }
    </NavDropdown>
  );
}
export default ProjectHeaderMenuItem;