import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaMicrophone, FaStop, FaPlay } from 'react-icons/fa';
import EqualizerAnimation from './EqualizerAnimation';
import { swalAlert } from 'utils';
const VoiceRecorder = ({ onSave, iconSize = 30, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [audioChunks, setAudioChunks] = useState([]);
  const chunksRef = useRef([]);
  useEffect(() => {
    if (showModal == false) {
      recorder?.stream.getTracks().forEach(track => track.stop());
      return
    }
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      swalAlert("L'enregistrement n'est pas supporté par ce navigateur.");
      setShowModal(false);
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const newRecorder = new MediaRecorder(stream);
        setRecorder(newRecorder);
        chunksRef.current = [];
        newRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunksRef.current.push(e.data);
          }
        };
        newRecorder.onstop = () => {
          const audioBlob = new Blob(chunksRef.current, { 'type': 'audio/mp4' });
          onSave(audioBlob);
        };
      })
      .catch(err => console.error(err));
  }, [showModal]);
  useEffect(() => {
    let interval = null;
    if (isRecording && timer < 60) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else if (!isRecording) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecording, timer]);
  const handleStartRecording = () => {
    if (recorder && recorder.state === 'inactive') {
      recorder.start();
      setIsRecording(true);
    }
  };
  const handleStopRecording = () => {
    if (recorder && recorder.state === 'recording') {
      recorder.stop();
      setIsRecording(false);
      setShowModal(false);
      chunksRef.current = [];
      setTimer(0);
      swalAlert("Enregistrement sauvegardé dans les pièces jointes.");
    }
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  return (
    <>
      <button className='btn btn-md btn-outline-primary py-2' onClick={e => setShowModal(true)} disabled={disabled}>
        <FaMicrophone size={iconSize} />
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-5">
            <div style={{ height: '90px' }}>
              <div className='mb-3'>{formatTime(timer)} / 01:00</div>
              <div className=''>
                {recorder && recorder.state === 'recording' && <EqualizerAnimation />}
              </div>
            </div>
            {recorder && recorder.state === 'recording' ? (
              <Button variant="danger" onClick={handleStopRecording}>
                <FaStop style={{ position: 'relative', top: '-3px', marginRight: '5px' }} /> Stop
              </Button>
            ) : (
              <Button variant="primary" onClick={handleStartRecording} disabled={!navigator.mediaDevices}>
                <FaPlay style={{ position: 'relative', top: '-2px', marginRight: '5px' }} /> Démarrer
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VoiceRecorder;
