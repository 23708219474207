import React, { useState, useEffect, useRef } from 'react'
// import '' from 
function UserProfile({ }) {
  const [bra, setBra] = useState('black');
  useEffect(() => { }, []);
  return (
    <div id="UserProfile">
      <div className='container'>
        User profile
      </div>
    </div>
  );
}

export default UserProfile;
