// Importations nécessaires
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from '../../../../redux/projectSlice';
import { setPreferences } from '../../../../redux/userPreferencesSlice';
import { MdOutlinePending } from "react-icons/md";

import ModalSelectFilters from 'components/projects/modals/ModalSelectFilters';
import { HtmlElement } from 'components/helpers/HtmlElements/HtmlElements';
import { debug } from 'utils';
import { FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import './project-layers-filter.css';

const ProjectLayersFilter = ({ className = '' }) => {
  const dispatch = useDispatch();
  const config = useSelector(state => state.config);
  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);

  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState([]);
  useEffect(() => {
    const current_filters = userPreferences.projects?.[project.projId]?.current_filters ?? [];
    if (current_filters) {
      setFilters(current_filters)
    }
  }, [project]);
  const update_filter = (index, event) => {
    const filtersCopy = [...filters];
    const filter = { ...filtersCopy[index] };
    filtersCopy[index] = filter;
    filter[event.target.name] = event.target.type == 'checkbox' ? event.target.checked : event.target.value;
    dispatch(setPreferences('projects', project.projId, {
      current_filters: [...filtersCopy]
    }));
  }
  const remove_filter = (index) => {
    const filtersCopy = [...filters];
    filtersCopy.splice(index, 1);
    dispatch(setPreferences('projects', project.projId, {
      current_filters: filtersCopy
    }));
  }



  const modalUpdateFilters = (filters) => {
    // const updatedFilters = filters.filter(f => f.value.trim() != '')
    dispatch(setPreferences('projects', project.projId, {
      current_filters: [...filters]
    }))
    setShowModal(false);
  }
  const optimizedFilters = useMemo(() =>
    filters.map(filter => ({
      ...filter,
      operatorLabel: project.filtering_operators.find(f => f.value === filter.operator)?.label || 'Inconnu'
    })), [filters, project.filtering_operators]);





  return (
    <div id="ProjectLayersFilter" className={className}>
      <dl className='layers'>
        <dt className='pointer'>
          Filtres

          <div onClick={() => setShowModal(true)}>
            <MdOutlinePending size={15} />
          </div>
        </dt>
        {optimizedFilters.map((filter, index) => (
          <dd key={index}>
            <span className='me-1'>
              <HtmlElement.leafCheckbox
                name="active"
                value={filter.active}
                checked={filter.active === true}
                iconSize={12}
                inputClassName=''
                onChange={e => update_filter(index, e)} />
            </span>
            {filter.label}&nbsp;
            {filter.operatorLabel}&nbsp;
            {Array.isArray(filter.value) ? filter.value.join('-') : filter.value}
            <div className='buttons'>
              <span onClick={e => remove_filter(index)} className='ps-2 pointer'>
                <FaTrash size={11} />
              </span>
            </div>
          </dd>
        ))}
      </dl>
      <ModalSelectFilters
        show={showModal}
        filters={filters}
        handleClose={() => setShowModal(false)}
        onChange={modalUpdateFilters}
      />
    </div>
  );
};

export default ProjectLayersFilter;
