import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css'
import { useDispatch, useSelector } from 'react-redux';
import TreeSpeciesSelector from '../../../../helpers/TreeSpeciesSelector';
import { HtmlElement } from '../../../../../helpers/HtmlElements/HtmlElements';
import { debug } from './../../../../../../utils'
import MapWidget from './../../../../../helpers/MapWidget/MapWidget';
import MediaAcquisitionToolbar from 'components/helpers/MediaAcquisitionToolbar/MediaAcquisitionToolbar';
import CustomAlert from 'components/helpers/CustomAlert/CustomAlert';
import LayerSelector from 'components/projects/helpers/LayerSelector';
const TreeFormFeatures = ({ item, onChange }) => {
  const project = useSelector(state => state.project);
  const [treeIdentityAddtionalItems, setTreeIdentityAddtionalItems] = useState([]);
  const [layersOptions, setLayersOptions] = useState([]);
  useEffect(() => {
    setLayersOptions(project.layers.map(layer => ({
      value: layer.itId, // Suppose que chaque élément de project.layers a une propriété 'id'
      label: layer.itName // Et une propriété 'name', et tu mets ce nom dans un tableau
    })));
  }, [])
  useEffect(() => {
    let addtionalItems = [];
    if (item?.projMeta != undefined) {
      const cat = item.projMeta.find(c => c.slug === 'treeIdentity')
      if (cat) {
        addtionalItems = cat.items.filter(i => i.active === true)
      }
    }
    setTreeIdentityAddtionalItems(addtionalItems);
    // set default meta value

  }, [item]);
  useEffect(() => {
    if (item?.projMeta && item.itId == '') {
      const defaultMeta = {};
      item.projMeta.forEach(cat => {
        cat.items.forEach(it => {
          if (it.active && it.hasOwnProperty('default')) {
            const key = `${cat.slug}/${it.id}`;
            defaultMeta[key] = it.default;
          }
        });
      });
      onChange({ meta: defaultMeta });
    }
  }, [item?.projMeta]);
  const executeActions = (actions, setItemMeta) => {
    const updatedMeta = { ...item.meta };
    actions.forEach(action => {
      switch (action.action) {
        case 'calculate':
          const result = parseFloat(eval(action.expression.replace('item.meta', 'updatedMeta')));
          updatedMeta[action.target] = result;
          break;
      }
    });
    onChange({ meta: updatedMeta });
  };
  const breakpointColumnsObj = {
    default: 3,
    992: 3,
    768: 2,
    500: 1
  };
  return (
    <div>
      <div className="mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className='card-title mb-3'>Identité</h6>
            <div className='card-text'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='row'>
                    <div className='col-4'>
                      <HtmlElement.formGroup value={item.itRef} name='itRef' label='Numéro :' onChange={onChange} required={true} />
                    </div>
                    <div className='col-8'>
                      <HtmlElement.formGroup value={item.itName} name='itName' label='Nom :' onChange={onChange} required={true} />
                    </div>
                  </div>
                  <HtmlElement.formGroup type="date" value={item.meta.diagnosisDate} name='meta.diagnosisDate' label='Date du diagnostic:' onChange={onChange} required={true} />
                  <LayerSelector
                    selectedId={item.itParentId}
                    name='itParentId'
                    onChange={onChange}
                    required={true}
                    allowCreate={true}
                  />
                  <div className="form-group">
                    <TreeSpeciesSelector
                      onUpdate={obj => onChange({ meta: { ...item.meta, ...obj } })}
                      essence_fr={item.meta.essence_fr}
                      essence_lt={item.meta.essence_lt}
                    />
                  </div>
                  {/* <HtmlElement.formGroup value={item.meta.origin} name='meta.origin' label='Origine : ' onChange={onChange} /> */}
                </div>
                {treeIdentityAddtionalItems.length > 0 &&
                  <div className='col-md-4'>
                    {treeIdentityAddtionalItems.map((it, idxi) => {
                      const Element = HtmlElement[it.type] || HtmlElement.input;
                      return Element && (
                        <div key={idxi} className='mb-2'>
                          <label className='form-group-label mb-1'>{it.name} : </label>
                          <Element
                            name={`meta.treeIdentity/${it.id}`}
                            value={item.meta[`treeIdentity/${it.id}`]}
                            onChange={onChange}
                            onBlur={() => it.onBlur && executeActions(it.onBlur)}
                            {...(it.options && { options: it.options })}
                          />
                        </div>
                      );
                    })}
                  </div>}
                <div className='col-md-4'>
                  {(item?.itGeometry && item?.itGeometry?.length == 2) &&
                    <div className='position-relative mb-3' style={{ paddingTop: '100%' }}>
                      <div className='position-absolute h-100 w-100' style={{ top: 0, left: 0 }}>
                        <MapWidget position={item.itGeometry} zoom={20} icon="tree" onMarkerChange={onChange} unlocked={true} />
                      </div>
                    </div>
                  }
                  <div className='mb-3 text-center'>
                    {false && (!item.itId || item.itId == '') &&
                      <CustomAlert input="Veuillez sauvegader le formulaire pour accéder aux outils multimédia" variant="warning" />
                    }
                    <MediaAcquisitionToolbar onMediaCreated={media => onChange({ medias: [...item.medias, media] })} medParentId={item.itId} disabled={!item.itId && false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {item.projMeta?.map((cat, idxc) => (
          (cat.active && cat.slug !== 'treeIdentity') && (
            <div className="accordion" id={`accordion${idxc}`} key={idxc}>
              <div className="accordion-item">
                <h6 className="accordion-header" id={`heading${idxc}`}>
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${idxc}`} aria-expanded="true" aria-controls={`collapse${idxc}`}>
                    {cat.name} :
                  </button>
                </h6>
                <div id={`collapse${idxc}`} className="accordion-collapse collapse" aria-labelledby={`heading${idxc}`} data-bs-parent={`#accordionExample${idxc}`}>
                  <div className="accordion-body">
                    <div className='card'>
                      <div className="card-body">
                        <div className='card-text'>
                          {cat.items.map((it, idxi) => {
                            const Element = HtmlElement[it.type] || HtmlElement.input;
                            return it.active && Element && (
                              <div key={idxi} className='mb-2'>
                                <label className='form-group-label mb-1'>{it.name} : </label>

                                <div className="input-group">

                                  {it.igBefore && <span className='input-group-text'>{it.igBefore}</span>}
                                  <Element
                                    name={`meta.${cat.slug}/${it.id}`}
                                    value={item.meta[`${cat.slug}/${it.id}`]}
                                    onChange={onChange}
                                    onBlur={() => it.onBlur && executeActions(it.onBlur)}
                                    {...(it.options && { options: it.options })}
                                  />
                                  {it.igAfter && <span className='input-group-text'>{it.igAfter}</span>}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </Masonry>
    </div>
  );
};
export default TreeFormFeatures;
