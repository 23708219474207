import React, { useMemo } from 'react';
import { Marker, Circle, Popup, Tooltip } from 'react-leaflet';
import { useSelector } from 'react-redux';
import useMarkerEventHandlers from './../../hooks/useMarkerEventHandlers';
import L from 'leaflet';
const CustomMarkerTree = ({ item }) => {
  const project = useSelector(state => state.project);
  const sortedData = useMemo(() => {
    return project.metaVisibility
      .filter(mv => mv.visible && item.meta[mv.metapath])
      .sort((a, b) => item.meta[b.metapath] - item.meta[a.metapath]);
  }, [project.metaVisibility, item.meta]);
  const circles = sortedData.map((mv, idx) => (
    <Circle
      key={`mv-${mv.id}-${idx}`}
      center={item.itGeometry}
      radius={parseFloat(item.meta[mv.metapath]) / 2}
      fillColor={mv.stroke}
      color={mv.stroke}
      fillOpacity={mv.opacity ? mv.opacity : 0.1}
      weight={1}
    >
      <Tooltip direction="right" style={{ padding: '0 0' }} >{mv.tooltip}: {item.meta[mv.metapath]}m</Tooltip>
    </Circle>
  ));
  const eventHandlers = useMarkerEventHandlers(item);
  const icon = useMemo(() => L.icon({
    iconUrl: `/img/markers/${item.itType}${item.unlocked ? '' : '-lk'}.png`,
    iconSize: [17, 25],
    iconAnchor: [9, 12]
  }), [item.itType, item.unlocked]);
  return (
    <>
      <Marker
        position={item.itGeometry}
        draggable={item.unlocked}
        icon={icon}
        eventHandlers={eventHandlers}
      >
        <Tooltip permanent>{item.itRef}</Tooltip>
      </Marker>
      {circles}
    </>
  );
};
export default CustomMarkerTree;
