import React, { useEffect, useState, useContext } from 'react';
import { debug } from './../../../utils';
import { DeviceContext } from './../../../DeviceContext'
import { useSelector } from 'react-redux';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { FiArrowLeftCircle } from 'react-icons/fi';
import ProjectLayersPanel from './sidebar/ProjectLayersPanel';
import './project-sidebar.css'
import MetaTogglerPanel from './sidebar/MetaTogglerPanel';
import ProjectFiltersPanel from './sidebar/ProjectFiltersPanel';
import ProjectItemSearch from './sidebar/ProjectItemSearch';
const ProjectSidebar = ({ }) => {
  const project = useSelector(state => state.project);
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const {
    userCan,
  } = useProjectMapContext();
  const [collapsed, setCollapsed] = useState(
    window.localStorage.getItem('projectSidebarCollapsed') ? window.localStorage.getItem('projectSidebarCollapsed') == 'true' : (isMobile || isTablet ? true : false)
  );
  useEffect(() => { window.localStorage.setItem('projectSidebarCollapsed', collapsed) }, [collapsed]);
  return (
    <div id="ProjectSidebarContainer" className={`${collapsed ? 'collapsed' : ''}`}>
      <div id="ProjectSidebar" className={`py-3 px-2`}>
        <div className={`${collapsed ? 'text-center' : 'text-end'} mb-3 `}>
        </div>
        {project?.projId &&
          <>
            <ProjectItemSearch className="mb-3" />
            <ProjectLayersPanel className="mb-3" />
            <MetaTogglerPanel className="mb-3" />
            <ProjectFiltersPanel className="mb-3" />
          </>}
        <>{debug(project?.items, 0)}</>
      </div>
      <span onClick={e => setCollapsed(!collapsed)} className='collapse-toggler'>
        <FiArrowLeftCircle size={30} style={{ transform: collapsed ? 'rotate(180deg)' : 'none' }} />
      </span>
    </div>
  );
};
export default ProjectSidebar;
