import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeFormFeatures from './form-tabs/TreeFormFeatures';
import TreeFormMedias from './form-tabs/TreeFormMedias';

import { useProjectMapContext } from './../../../hooks/useProjectMapContext';
import useItemsService from './../../../hooks/useItemsService';
import { HtmlElement } from './../../../../helpers/HtmlElements/HtmlElements';
import { debug, isDev } from './../../../../../utils';
import './../../../../../css/nav-tabs.css'
import './css/masonry.scss';
import ResponsiveNavTabs from './../../../../helpers/ResponsiveNavTabs/ResponsiveNavTabs';
const TreeFrom = ({ item = null, onUpdate }) => {

  const {
    userCan,
    setItemSelected,
  } = useProjectMapContext();
  const {
    save_item, update_item,
  } = useItemsService();
  const project = useSelector(state => state.project);
  const [activeTab, setActiveTab] = useState(0);
  const [itemCopy, setItemCopy] = useState({ initialized: false, meta: {} });
  const [navTabs, setNavTabs] = useState();
  const [dirty, setIsDirty] = useState(false);

  useEffect(() => {

    const navTabs = [
      'Caratéristiques',
      'Instantanés',
      'Tâches',
      'Pièces jointes'
    ]
    if (isDev()) {
      navTabs.push('Debug');
    }
    setNavTabs(navTabs)
  }, []);
  useEffect(() => {
    setItemCopy({ ...item });
  }, [item]);
  const handleChange = (obj) => {
    if (obj.target) {
      const { name, value } = obj.target;

      const parts = name.split('.');
      let newObj
      if (parts.length == 1) {
        newObj = { ...itemCopy, [parts[0]]: value };
      } else {
        newObj = { ...itemCopy, [parts[0]]: { ...itemCopy[[parts[0]]], [parts[1]]: value } }
      };
      setItemCopy(prevItem => ({ ...prevItem, ...newObj }));
    } else {

      setItemCopy(prevItem => ({ ...prevItem, ...obj }));
    }
    setIsDirty(true);
  };
  const submit = async () => {
    let response = null;
    if (!itemCopy.itId || itemCopy.itId == '') {
      item = await save_item(itemCopy);
      setItemCopy({ ...itemCopy, ...item })
      onUpdate(item);
    } else {
      item = await update_item(itemCopy);
      setItemCopy({ ...itemCopy, ...item })
      onUpdate(item);
    }
    setIsDirty(false);
    // setItemSelected(null);
  }
  return (
    <div id="TreeFrom" className='d-flex flex-column h-100 flex-grow-1'>
      <ResponsiveNavTabs tabNames={navTabs} activeTab={activeTab} setActiveTab={setActiveTab} >
        {userCan('editItem') &&
          <HtmlElement.submit text="Sauvegarder" onClick={submit} className={`${dirty ? 'btn-danger' : 'btn-outline-primary'}`} />
        }
      </ResponsiveNavTabs>
      <div className={`tab-content flex-grow-1`} >
        <div className={`tab-pane fade show ${activeTab == 0 ? 'active' : ''}`} >
          <TreeFormFeatures item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab === 1 ? 'active' : ''}`} >

        </div>
        <div className={`tab-pane fade show ${activeTab === 2 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 3 ? 'active' : ''}`} >
          <TreeFormMedias item={itemCopy} onChange={handleChange} />
        </div>
        <div className={`tab-pane fade show ${activeTab === 4 ? 'active' : ''}`} >
          {debug(itemCopy)}
        </div>
      </div>
    </div>
  );
};
export default TreeFrom;
