import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserSignIn from './UserSignIn';
import UserLostPwd from './UserLostPwd';
import UserSignUp from './UserSignUp';
import UserPwd from './UserPwd';
import API from '../../api';
import './user-signin-modal.css';
import { debug } from './../../utils';
function UserSignInModal({ show, onSuccess = null, onClose = null, options = {} }) {
  const navigate = useNavigate;
  const defaultTab = 'signIn';
  const user = useSelector((state) => state.user);
  const [tab, setTab] = useState(defaultTab);
  const [optionsCopy, setOptionsCopy] = useState({});
  useEffect(() => {
    setOptionsCopy(options)
  }, [options])
  useEffect(() => {
    if (user.isSignedIn) {
      onSuccess(user);
      // if (options.onSigned) {
      //   navigate(options.onSigned)
      // }
    }
  }, [user])
  const handleOnTabSwitch = (newTab, options = null) => {
    if (options) {
      setOptionsCopy(options); // passage de data entre les tabs
    }
    if (newTab != 'close') {
      setTab(newTab != '' ? newTab : defaultTab);
    } else {
      setTab(defaultTab);
      handleOnClose();
    }
  }
  const handleOnClose = (newTab) => {
    if (tab !== defaultTab) {
      setTab(defaultTab);
    } else {
      onClose()
    }
  }
  return (
    <Modal show={show} onHide={handleOnClose} className='userSignInModal'>
      <Modal.Header closeButton={options.close != false}>
        <div className='text-center flex-grow-1'>
          <img src='/img/logo-header.png' alt="" style={{ height: '50px' }} />
        </div>
      </Modal.Header>

      <Modal.Body>
        {tab === 'signIn' && <UserSignIn onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
        {(options?.userType && tab === 'signUp') && <UserSignUp onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
        {tab === 'lostPwd' && <UserLostPwd onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
        {tab === 'newPwd' && <UserPwd onTabSwitch={handleOnTabSwitch} options={optionsCopy} />}
      </Modal.Body>
    </Modal >
  );
}

export default UserSignInModal;
