//MapContextMenu.js
import React, { useEffect, useState, useRef } from 'react';
import useItemsService from '../hooks/useItemsService';
import { useProjectMapContext } from '../hooks/useProjectMapContext';
import { PiTreeBold, PiInfo, PiTrashThin, PiLock } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { debug } from '../../../utils';
import ProjectIcons from '../helpers/ProjectIcons';
const ItemContextMenu = ({ options = {}, onClick }) => {
  const ref = useRef();
  const [item, setItem] = useState({})
  const project = useSelector(state => state.project);

  const { handleItemMenuClick } = useItemsService();
  const { userCan, } = useProjectMapContext();


  useEffect(() => {
    setItem(options.item)
  }, [options])
  return (
    <div id="ItemContextMenu" className={`contextMenu`} style={{ left: options.left, top: options.top }} ref={ref}>
      <div className={`header d-flex justify-content-between pe-2 pointer`}>
        <div className='main'>
          <ProjectIcons type={item.itType} size={20} className='me-1' />
          {item.itRef}
        </div>
        <span onClick={e => handleItemMenuClick('close')}>&times;</span>
      </div>
      <div className='body'>
        <ul>
          {(project && userCan('editItem')
            && item.itFeatures?.constrained != true
            && item.itType != 'alignment'
            && item.itType != 'woodarea'
          ) &&
            <li className='pointer' onClick={e => handleItemMenuClick('unlock')} ><span className='me-2'><PiLock /></span>
              {item.unlocked ? 'Verrouiller' : 'Déverrouiller'}
            </li>
          }
          <li className='pointer' onClick={e => handleItemMenuClick('edit')} ><span className='me-2'><PiInfo /></span>Informations</li>
          {(project && userCan('editItem')) &&
            <li className='pointer' onClick={e => handleItemMenuClick('delete')} ><span className='me-2'><PiTrashThin /></span>Supprimer</li>
          }
        </ul>
      </div>
      <div className='footer'>
      </div>
    </div >
  );
};
export default ItemContextMenu;
