import { useRef } from 'react';

import { useProjectMapContext } from './useProjectMapContext';
import useItemsService from './useItemsService';
const useMarkerEventHandlers = (item) => {
  const { setItemContextMenuOptions } = useProjectMapContext();
  const { update_item } = useItemsService();
  const isDragging = useRef(false);
  const relockAt = useRef(0);
  const eventHandlers = {
    dragstart: (e) => {
      isDragging.current = true;
    },
    drag: (e) => {
      relockAt.current = new Date().getTime() + 5000;
    },
    dragend: (e) => {
      const { lat, lng } = e.target.getLatLng();
      update_item({ ...item, itGeometry: [lat, lng] });
    },
    mouseover: (e) => {
      e.target.openPopup();
    },
    mouseout: (e) => {
      e.target.closePopup();
    },
    click: (event) => {
      setItemContextMenuOptions({
        show: true,
        item: item,
        left: event.originalEvent.layerX - 20,
        top: event.originalEvent.layerY - 20,
        latlng: [event.latlng.lat, event.latlng.lng],
      });
    },
  };
  return eventHandlers;
};
export default useMarkerEventHandlers;
