import { useEffect, useState } from 'react';

const useCurrentLocation = () => {
  const [location, setLocation] = useState([0, 0]); // Initialisation avec une position par défaut
  const [error, setError] = useState(null);

  const handleSuccess = ({ coords }) => {
    setLocation([coords.latitude, coords.longitude]);
  };

  const handleError = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur.');
      return;
    }

    // L'objet `watchPosition` est utilisé pour s'abonner aux mises à jour de la position.
    const watcher = navigator.geolocation.watchPosition(handleSuccess, handleError);

    // Retourne une fonction de nettoyage qui sera appelée pour annuler l'écoute lors du démontage du composant
    return () => navigator.geolocation.clearWatch(watcher);
  }, []);

  return [location, error];
};

export default useCurrentLocation;
