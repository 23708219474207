import React, { useState, useEffect, useRef } from 'react';
import { debug, swalAlert } from 'utils';
import { HtmlElement } from './../../../helpers/HtmlElements/HtmlElements';
import MediasList from 'components/medias/MediasList'
import { useProjectMapContext } from './../../hooks/useProjectMapContext'
const ProjectMedias = ({ project, onChange }) => {
  const { mapRef } = useProjectMapContext();
  useEffect(() => { }, []);
  const handleMediasChange = (medias) => {
    onChange({ medias: medias });
    //swalAlert(medias.filter(m => m.medStatus == 'deleted').length)


  }
  return (
    <div>
      <MediasList medias={project.medias} onChange={handleMediasChange} parentId={project.projId} />
      {debug(project.medias, 1)}
    </div>
  );
}
export default ProjectMedias;
