import React, { Children, useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useProjectMapContext } from 'components/projects/hooks/useProjectMapContext';
const MapWidget = ({ position, zoom = 16, icon = 'marker', children = null, onMarkerChange = null, unlocked = false }) => {
  const {
    mapType, setMapTypeId, } = useProjectMapContext();
  useEffect((
  ) => {
    setMapTypeId('googleHybrid');
  }, []);
  return (
    <>
      {(position && mapType?.type) &&
        <>
          <MapContainer
            center={position}
            zoom={zoom}
            style={{ width: '100%', maxHeight: '100%', height: "600px", margin: '0 auto' }}
            scrollWheelZoom={true}
          >
            {false && <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />}
            <ReactLeafletGoogleLayer apiKey={mapType.apikey} type={mapType.type} />
            <UpdateMarker position={position} icon={icon} onMarkerChange={onMarkerChange} unlocked={unlocked} />
            {children}
          </MapContainer>
        </>
      }
    </>
  );
};
const UpdateMarker = ({ position, icon, unlocked = false, onMarkerChange }) => {
  const [unlockedCopy, setUnlockedCopy] = useState(unlocked)
  const customIcon = L.icon({
    iconUrl: `/img/markers/${icon}${unlockedCopy ? '' : '-lk'}.png`,
    iconSize: [17, 25],
    iconAnchor: [9, 12],
    popupAnchor: [0, -25], // Point à partir duquel la popup sera ouverte par rapport à iconAnchor
  });
  const eventHandlers = {
    dragend: (event) => {
      const marker = event.target;
      const newPosition = marker.getLatLng();
      if (onMarkerChange) {
        onMarkerChange({ itGeometry: [newPosition.lat, newPosition.lng] });
      }
    },
  };
  return <Marker position={position} icon={customIcon} draggable={unlockedCopy} eventHandlers={eventHandlers} />;
};
export default MapWidget;
