import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PiNoteFill } from "react-icons/pi";
import { HtmlElement } from './../HtmlElements/HtmlElements';

const MemoEditor = ({ iconsSize = 30, memo = '', onSave, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [memoCopy, setMemoCopy] = useState(memo);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleSave = () => {
    onSave(memoCopy);
    setMemoCopy('');
    setShowModal(false);
  };
  return (
    <>
      <button className='btn btn-outline-primary py-2' onClick={handleOpenModal} disabled={disabled}>
        <PiNoteFill size={iconsSize} />
      </button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control mb-5"
            value={memoCopy}
            onChange={(e) => setMemoCopy(e.target.value)}
            rows="10"
          ></textarea>
          <div className='mb-3'>
            <HtmlElement.submit onClick={handleSave} />
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemoEditor;
