import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from './../api';
export const fetchConfig = createAsyncThunk(
  'config/fetchConfig',
  async () => {
    const response = await API.get('/config'); // Assure-toi que cette ligne corresponde à la manière dont tu interagis avec ton API
    return JSON.parse(atob(response.data))
  }
);
const configSlice = createSlice({
  name: 'config',
  initialState: {
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {
    setConfig(state, action) {
      Object.assign(state, { ...action.payload, isLoading: false });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.status = 'succeeded';
        Object.assign(state, { ...action.payload });

      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
