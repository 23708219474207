import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { swalAlert } from 'utils';

import { HtmlElement } from './../../helpers/HtmlElements/HtmlElements';
import { debug } from 'utils';
import { cloneDeep } from 'lodash';
import ResponsiveNavTabs from 'components/helpers/ResponsiveNavTabs/ResponsiveNavTabs';
const ModalSelectFilters = ({ show, handleClose = null, onChange, filters = [] }) => {
  const project = useSelector(state => state.project);
  const [filtersCopy, setFiltersCopy] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabNames, setTabNames] = useState([]);
  const [category, setCategory] = useState({});
  useEffect(() => {
    const filtersCopy = filters.map(f => {
      if (Array.isArray(f.value)) {
        return { ...f, value: f.value.join('-') };
      }
      return f;
    });
    setFiltersCopy(filtersCopy);
    const tabNames = [];
    project.projMeta.tree.forEach(cat => {
      tabNames.push(cat.name);
    });
    setTabNames(tabNames);
  }, [show]);
  useEffect(() => {
    setCategory(project.projMeta.tree[activeTab])
  }, [activeTab]);
  const filtersMap = React.useMemo(() => {
    return filtersCopy.reduce((acc, filter) => {
      acc[filter.key] = filter;
      return acc;
    }, {});
  }, [filtersCopy]);
  const setFilter = (event) => {
    const { name, value, type, checked } = event.target;

    const [_, key, filterProperty] = name.split('_');
    const [categorySlug, itemId] = key.split('/');
    const newFilterValue = type !== 'checkbox' ? value : checked;
    const category = project.projMeta.tree.find(c => c.slug === categorySlug);
    const item = category?.items.find(i => i.id === itemId);
    const updatedFilters = filtersCopy.map(filter => {
      if (filter.key === key) {
        return { ...filter, [filterProperty]: newFilterValue };
      }
      return filter;
    });
    if (!updatedFilters.some(filter => filter.key === key)) {
      const newFilter = {
        key,
        operator: '=',
        value: '',
        label: item?.name || '',
        active: true,
        [filterProperty]: newFilterValue,
      };
      updatedFilters.push(newFilter);
    }
    setFiltersCopy(updatedFilters);
  };
  const equalOperatorOnly = project.filtering_operators.filter(f => f.value === '=');
  const allOperators = useMemo(() => {
    return project.filtering_operators;
  }, [project.filtering_operators]);
  const handleOnSaveClick = () => {

    const checkedFilters = [];
    try {
      filtersCopy.forEach(filter => {
        const filterCopy = cloneDeep(filter);
        if (typeof filterCopy.value === 'string') {
          filterCopy.value = filterCopy.value.trim() || '';
        }
        if (filterCopy.value !== '') {
          switch (filterCopy.operator) {
            case 'between':
              const parts = filterCopy.value.split('-').map(part => part.trim()); // S'assure que les espaces sont retirés des extrémités
              if (parts.length !== 2 || parts[0] === '' || parts[1] === '') {
                throw ({ message: 'Les valeurs des filtres "entre" doivent être au format Mini-Maxi.' });
              }
              filterCopy.value = parts.sort();
              break;
          }
          checkedFilters.push(filterCopy);
        }
      });
      onChange([...checkedFilters]);
      return;
    } catch (e) {

      swalAlert(e.message);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter des filtres</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <label className='form-group-label'>Section :</label>
          <ResponsiveNavTabs
            activeTab={activeTab} tabNames={tabNames} setActiveTab={setActiveTab} forceSelect={true} size="sm"
          > <HtmlElement.submit onClick={handleOnSaveClick} size='sm' />
          </ResponsiveNavTabs>
        </div>
        <div className={`tab-content flex-grow-1`}
          style={{ borderTop: 'none!important', borderTopLeftRadius: '0!important', borderTopRightRadius: '0!important' }}
        >
          <div className={`tab-pane fade show active`} >
            {category.items &&
              <table className='w-100'>
                <tbody>
                  {category.items.map((it, idxi) => {
                    const Element = HtmlElement[it.type] || HtmlElement.input; // Sélection dynamique du composant
                    return (
                      <tr key={idxi}>
                        <td className='py-2'>
                          <HtmlElement.checkbox
                            name={`filter_${category.slug}/${it.id}_active`}
                            value={filtersMap[`${category.slug}/${it.id}`]?.active || false}
                            checked={filtersMap[`${category.slug}/${it.id}`]?.active || false}
                            iconSize={12}
                            inputClassName=''
                            label={it.name}
                            onChange={setFilter} />
                        </td>
                        <td className='py-2' style={{ width: '100px' }}>
                          <HtmlElement.select
                            name={`filter_${category.slug}/${it.id}_operator`}
                            value={filtersMap[`${category.slug}/${it.id}`]?.operator || ''}
                            options={it.type !== 'select' ? allOperators : equalOperatorOnly}
                            onChange={setFilter}
                            className='text-center'
                            style={{
                              flex: '0 0 50px',
                              textAlign: 'text-start'
                            }} />
                        </td>
                        <td className='py-2'>
                          <Element
                            value={filtersMap[`${category.slug}/${it.id}`]?.value || ''}
                            onChange={setFilter}
                            name={`filter_${category.slug}/${it.id}_value`}
                            options={it.options ? it.options : []}
                            placeholder={filtersMap[`${category.slug}/${it.id}`]?.operator == 'between' ? 'valeur1-valeur2' : ''}

                          />
                        </td>
                      </tr>
                    );

                  })}
                </tbody>
              </table>
            }
          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalSelectFilters;
