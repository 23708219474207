import React, { useEffect, useState } from 'react';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { debug, swalAlert } from './../../utils'
import API from '../../api';
import DialCodes from '../helpers/DialCodes/DialCodes';
import PhoneNumber from '../helpers/PhoneNumber/PhoneNumber';
import { setUser } from './../../redux/userSlice';
import { BiShow, BiHide } from "react-icons/bi";
import { useSelector } from 'react-redux'; // Importe useSelector
import PasswordChecker from './../helpers/PasswordChecker/PasswordChecker'
const UserSignUp = ({ onTabSwitch, options = null }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const [signUpData, setSignUpData] = useState({
    userFirstname: '',
    userName: '',
    userEmail: '',
    userPhone: '',
    phoneNumber: '',
    password: ''
  });
  const [submission, setSubmission] = useState({ status: '', message: ' ' });
  const [loyaltyNumber, setLoyaltyNumber] = useState('0');
  const [inputType, setInputType] = useState('password');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  useEffect(() => {
    setSubmission({ status: '' });
  }, [signUpData]);
  const handleSignUpSubmit = async (event) => {
    setSubmission({ status: 'pending' });
    event.preventDefault();
    try {
      const data = { ...signUpData, ...options };
      const response = await API.post('/user/signUp', data);
      if (response.data.status == 'active') {
        if (options.onSigned) {
          navigate(options.onSigned)
        } else {
          swalAlert('Votre compte a été créé.');
        }
        dispatch(setUser({ ...response.data, isSignedIn: true }));
        onTabSwitch('signIn')
      }
    } catch (error) {
      let errors = [];
      if (error.response.data.error.code == 400) {
        errors = JSON.parse(error.response.data.error.message);
      }
      setSubmission({ status: "error", code: error.response.data.error.code, message: error.response.data.error.message, errors: errors });
    }
  }
  return (
    <div className='user-form-container '>
      <Form onSubmit={(e) => { e.preventDefault() }}>
        <h1 className=''>Création de compte</h1>
        <Form.Group className="form-group mb-1 pb-0">
          <label className="form-group-label" required>Prénom : </label>
          <Form.Control
            type="text"
            size='sm'
            autocomplete="given-name"
            placeholder=""
            value={signUpData.userFirstname}
            onChange={(e) => { setSignUpData({ ...signUpData, userFirstname: e.target.value }); }}
            disabled={submission.status == 'pending'}
          />
        </Form.Group>
        <Form.Group className="form-group mb-1 pb-0">
          <label className="form-group-label" required>Nom : </label>
          <Form.Control
            type="text"
            size='sm'
            autocomplete="name"
            placeholder=""
            value={signUpData.userName}
            onChange={(e) => { setSignUpData({ ...signUpData, userName: e.target.value }); }}
            disabled={submission.status == 'pending'}
          />
        </Form.Group>
        <Form.Group className="form-group mb-1 pb-0">
          <label className="form-group-label" required>Email : </label>
          <Form.Control
            size='sm'
            type="text"
            name='userEmail'
            autocomplete="email"
            placeholder=""
            value={signUpData.userEmail}
            onChange={(e) => { setSignUpData({ ...signUpData, userEmail: e.target.value }); }}
            disabled={submission.status == 'pending'}
          />
        </Form.Group>
        <Form.Group className="form-group mb-1 pb-0">
          <label className="form-group-label" required>Téléphone : </label>
          <PhoneNumber number={signUpData.userPhone} onUpdate={(phoneNumber) => { setSignUpData({ ...signUpData, userPhone: phoneNumber }); }}
          />
        </Form.Group>
        <Form.Group className="form-group mb-1 pb-0">
          <label className="form-group-label" required>Mot de passe : </label>
          <InputGroup className='mb-1'>
            <Form.Control
              type={inputType}
              size='sm'
              value={signUpData.password}
              onChange={(e) => { setSignUpData({ ...signUpData, password: e.target.value }); }}
              disabled={submission.status == 'pending'}
            />
            <Button size='sm' variant="outline-secondary" onClick={() => { setInputType(inputType == 'password' ? 'text' : 'password') }}>
              {inputType === 'password' ? <BiShow /> : <BiHide />}
            </Button>
          </InputGroup>
          <PasswordChecker password={signUpData.password} isValid={v => setPasswordIsValid(v)} />
        </Form.Group>


        <Form.Group className="text-left mb-3">
          <label className='form-group-label'>
            Avez-vous une carte de fidélité de l'Affaire à Repasser :
          </label> <br />
          <label className="checkbox-label me-5">
            <input type="radio" value="true" checked={loyaltyNumber == '0'} onChange={e => { setLoyaltyNumber('0'); setSignUpData({ ...signUpData, custLoyaltyNumber: '' }) }} /> Non
          </label>
          <label className="checkbox-label">
            <input type="radio" value="true" checked={loyaltyNumber == '1'} onChange={e => setLoyaltyNumber('1')} />Oui
          </label>

          {loyaltyNumber == '1' && <Form.Control
            size='sm'
            type="number"
            name='custLoyaltyNumber'
            autocomplete="off"
            className="text-start"

            placeholder="Numéro de carte indiqué sous le qr-code de votre carte"
            value={signUpData.custLoyaltyNumber}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 6) {
                setSignUpData({ ...signUpData, custLoyaltyNumber: value });
              }
            }}
            disabled={submission.status == 'pending'}
          />}
        </Form.Group>




        <Form.Group className="text-center">
          <Alert
            className='py-2 px-3 small'
            variant={submission.status == 'success' ? 'success' : 'danger'}
            dismissible={true}
            transition={true}
            show={submission.status === 'error'}
            onClose={() => setSubmission({ status: '' })}
          >
            {(submission.status === 'error' && submission.code === 400) ? (
              <p className='text-start'>
                {submission.errors.length > 1 ?
                  ('Les champs suivants sont incomplets ou invalides ') :
                  ('Le champ suivant est incomplet ou invalide ')}
                : <br />
                {JSON.parse(submission.message).map(error => (
                  <>- {t(error)}<br /></>
                ))}
              </p>
            ) : (<>{t(submission.message)}</>)
            }
          </Alert>
        </Form.Group>



        <Form.Group className="form-group-submit">
          <Button variant="warning" type="button" disabled={submission.status == 'pending' || submission.status == 'success'} onClick={handleSignUpSubmit}>
            Je crée mon compte
          </Button>
        </Form.Group>
        {debug(submission, 0)}
      </Form>
      <p className='small pt-4 pb-5'>
        <span className=' d-inline-block pointer' onClick={e => onTabSwitch('')}>Annuler</span>
        {(submission.code && submission.code === 409) &&
          <strong className='pointer d-inline-block ms-5' onClick={e => onTabSwitch('lostPwd')}>Demander un nouveau mot de passe</strong>
        }
      </p>
    </div >
  );
}
export default UserSignUp;

