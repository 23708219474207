import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-editable'
import { useProjectMapContext } from './../../hooks/useProjectMapContext';
import useItemsService from './../../hooks/useItemsService';
import useMapService from './../../hooks/useMapService';
import './custom-polyline.css';
import CustomMarkerTree from './CustomMarkerTree';
const CustomPolyline = ({ item }) => {
  const project = useSelector((state) => state.project);
  const [linePoints, setLinePoints] = useState([...item.itGeometry]);
  const [circleCenters, setCircleCenters] = useState([...item.itGeometry]);
  const [children, setChildren] = useState([]);
  const { mapRef, setItemContextMenuOptions } = useProjectMapContext();
  const polylineRef = useRef(null);
  const relockAt = useRef(0);
  const { setCurrentDrawingLayer } = useMapService()
  const color = item.itFeatures?.color || '#3583e9';
  useEffect(() => {
    if (item.itType == 'alignment') {
      get_children();
    }
    if (item.unlocked) {
      polylineRef.current?.enableEdit();
      setCurrentDrawingLayer(polylineRef.current);
    } else {
      polylineRef.current?.disableEdit();
    }
  }, [item]);
  const get_children = () => {
    setChildren(project.items.filter(it => it.itParentId == item.itId));
  }
  useEffect(() => {
    polylineRef.current = L.polyline(linePoints, {
      color: color,
      weight: 5,
      item: { itId: item.itId, itType: item.itType }

    }).addTo(mapRef.current);
    polylineRef.current.on('click', function (event) {
      setItemContextMenuOptions({
        show: true,
        item: item,
        left: event.originalEvent.layerX - 20,
        top: event.originalEvent.layerY - 20,
        latlng: [event.latlng.lat, event.latlng.lng],
      })
      L.DomEvent.stopPropagation(event)
    });
    polylineRef.current.on('mouseover', function (e) {
      var popup = L.popup()
        .setLatLng(e.latlng)
        .setContent(item.itName)
        .openOn(mapRef.current);
    });
    polylineRef.current.on('mouseout', function (e) {
      mapRef.current.closePopup(); // Ferme le popup
    });
    polylineRef.current.on('editable:vertex:dragend', function (e) {
      setCurrentDrawingLayer(polylineRef.current);
    });
    return () => {
      polylineRef.current.off('editable:vertex:dragend');
      mapRef.current.removeLayer(polylineRef.current);
    };
  }, []);
  useEffect(() => {
    setCircleCenters([...linePoints]);
  }, [linePoints]);
  return (
    <>
      {item.itType == 'alignment' && children.map(item => (
        item.match_filters &&
        <CustomMarkerTree item={item} key={item.itId} />
      ))}
    </>
  );
};
export default CustomPolyline;