import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
// Charge les préférences utilisateur depuis localStorage au démarrage
export const loadUserPreferences = createAsyncThunk(
  'userPreferences/load',
  async (_, { dispatch }) => {
    const storedPreferences = localStorage.getItem('userPreferences');
    if (storedPreferences) {
      const preferences = JSON.parse(storedPreferences);
      dispatch(userPreferencesSlice.actions.setPreferencesFromStorage(preferences));
    }
  }
);
const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: {
    general: {}, // Préférences générales
    projects: {}, // Préférences spécifiques aux entités "projects"
  },
  reducers: {
    setPreferences: {
      reducer(state, action) {
        const { entity, id, preferences } = action.payload;
        if (entity && id) {
          if (!state[entity]) {
            state[entity] = {};
          }
          if (!state[entity][id]) {
            state[entity][id] = {};
          }
          state[entity][id] = {
            ...state[entity][id],
            ...preferences
          };
        }
        /*else if (entity) {
          state[entity] = preferences;
        } else {
          return preferences;
        }
        */
        // Mise à jour de localStorage
        localStorage.setItem('userPreferences', JSON.stringify(state));
      },
      prepare(entity, id, preferences) {
        return { payload: { entity, id, preferences } };
      },
    },
    setPreferencesFromStorage(state, action) {

      return action.payload;
    },
  }
});
export const { setPreferences } = userPreferencesSlice.actions;
export default userPreferencesSlice.reducer;
