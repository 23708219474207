import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Importe useSelector
import { setUser } from './../../redux/userSlice';
import { useTranslation } from 'react-i18next'
import { debug } from './../../utils'
import { Form, Button, Alert, InputGroup } from 'react-bootstrap'; // Importez les composants React Bootstrap nécessaires
import { Link } from 'react-router-dom';
import { BiShow, BiHide } from "react-icons/bi";
import API from '../../api';
import './user-form.css';
function UserSignIn({ onTabSwitch, options }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const [signInData, setSignInData] = useState({ email: '', password: '' });
  const [submission, setSubmission] = useState({ status: '', message: ' ' });
  const [inputPwdType, setInputPwdType] = useState('password');
  const handleSignInSubmit = async (event) => {
    setSubmission({ status: 'pending' });
    event.preventDefault();
    try {
      const response = await API.post('/user/signIn', signInData);
      if (response.data.status == 'active') {
        localStorage.setItem('userToken', response.data.token);
        dispatch(setUser(response.data));
        onTabSwitch('close')
        if (options.onSigned) {
          navigate(options.onSigned)
        }
      } else {
        setSubmission({ status: '' });
      }
    } catch (error) {
      setSubmission({ status: "error", code: error.response.data.error.code, message: error.response.data.error.message });
    }
  };
  return (
    <div className='user-form-container '>
      <Form onSubmit={handleSignInSubmit}>
        <h1 className=''>Connexion</h1>
        <Form.Group className="form-group">
          <Form.Label>Adresse email</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={signInData.email}
            onChange={(e) => { setSignInData({ ...signInData, email: e.target.value }); setSubmission({ status: '' }); }}
            disabled={submission.status == 'pending'}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Mot de passe</Form.Label>
          <InputGroup>
            <Form.Control
              type={inputPwdType}
              value={signInData.password}
              onChange={(e) => { setSignInData({ ...signInData, password: e.target.value }); setSubmission({ status: '' }); }}
              disabled={submission.status == 'pending'}
            />
            <Button variant="outline-secondary" onClick={() => { setInputPwdType(inputPwdType == 'password' ? 'text' : 'password') }}>
              {inputPwdType === 'password' ? <BiShow /> : <BiHide />}
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group className="text-center">
          <Alert
            className='py-2 px-3 small'
            variant={submission.status == 'success' ? 'success' : 'danger'}
            dismissible={true}
            transition={true}
            show={submission.status === 'error'}
            onClose={() => setSubmission({ status: '' })}
          >
            {submission.message}
          </Alert>
        </Form.Group>
        <Form.Group className="form-group-submit">
          <Button variant="warning" type="submit" disabled={submission.status == 'pending' || submission.status == 'success'}>
            Me connecter
          </Button>
        </Form.Group>
        <p className='small pt-4 pb-5'>
          <span className=' d-inline-block pointer' onClick={e => onTabSwitch('lostPwd')}>Mot de passe oublié</span>
          {
            (options?.userType) &&
            <strong className='pointer d-inline-block ms-5' onClick={e => onTabSwitch('signUp')}>Créer un compte</strong>
          }
        </p>
      </Form>


    </div >
  );
}
export default UserSignIn;
