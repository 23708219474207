import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DeviceProvider } from './DeviceContext'; // Importe DeviceProvider
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import { fetchConfig } from './redux/configSlice';
import { updateUser } from './redux/userSlice';
import { loadUserPreferences } from './redux/userPreferencesSlice';
import API from './api';
// Layouts
import AppLayout from './layouts/AppLayout';
// Routes Components
import HomeDefault from './components/homes/HomeDefault';
import HomeMain from './components/homes/HomeMain';
import ProjectMap from './components/projects/ProjectMap';
import UserProfile from 'components/user/UserProfile';
import UserHome from 'components/user/UserHome';

// Styles 
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/app.scss';
import './css/forms.css';

// Route Definitions
const routes = [
  { path: "/", element: <HomeDefault /> },
  { path: "/home", element: <HomeMain />, requireAuth: true },
  { path: "/user/home", element: <UserHome />, requireAuth: true },
  { path: "/project/map/:projId?", element: <ProjectMap />, requireAuth: true },
];
function App() {
  if (console && process.env.NODE_ENV === 'production') {

  }


  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminAuthToken = params.get('adminAuthToken');
    if (adminAuthToken) {
      checkToken(adminAuthToken, params.get('userId'));
    } else {
      setAllowed(true);
    }
  }, []);
  useEffect(() => {
    dispatch(loadUserPreferences());
    dispatch(fetchConfig());
  }, [dispatch]);
  const checkToken = async (adminAuthToken, userId) => {
    let querystring = 'adminAuthToken=' + adminAuthToken;
    querystring += '&userId=' + userId;
    const response = await API.get('/admin/signin?' + querystring);
    if (response.data.isAdmin == true) {
      setAllowed(true)
      dispatch(updateUser(response.data));
      window.sessionStorage.setItem('backoffice_url', document.referrer);
    } else {
      window.location.href = '/';
    }
  }
  /* basename={getBasename(window.location.pathname)} */
  return (
    <DeviceProvider>
      {allowed &&
        <Router>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path}
                path={route.path}
                element={<AppLayout requireAuth={route.requireAuth} requireAdmin={route.requireAdmin} >
                  {route.element}
                </AppLayout>}
              />
            ))}
          </Routes>
        </Router>
      }
    </DeviceProvider>
  );
}
export default App;
