//MediaAcquisitionToolbar.js
import React, { useEffect, useRef, useState } from 'react';
import CameraCapture from '../CameraCapture/CameraCapture';
import VoiceRecorder from '../VoiceRecorder/VoiceRecorders';
import MemoEditor from '../MemoEditor/MemoEditor';
import { formatDate, swalAlert } from 'utils';
import API from 'api';
import HandDrawnEditor from '../HandDrawnEditor/HandDrawnEditor';
const MediaAcquisitionToolbar = ({ modules, onMediaCreated, iconsSize = 30, medParentId, disabled = false }) => {
  const saveMemo = async (memo) => {
    if (memo.trim().length > 0) {
      const blob = new Blob([memo], { type: 'text/plain' });
      saveBlob(blob)
    }
  }
  const saveBlob = async (blob) => {
    const datetime = formatDate(new Date(), 'Y/m/d H:i:s').replace(/[^0-9]/g, '-');
    const formData = new FormData();
    formData.append("file", blob); // Ajoute le Blob
    formData.append("medParentId", medParentId); // Ajoute les métadonnées
    let medName = '';
    switch (blob.type) {
      case 'text/plain':
        medName = 'memo-' + datetime + '.txt';
        break;
      case 'audio/mp4':
        medName = 'audio-' + datetime + '.mp4';
        break;
      case 'image/png':
        medName = 'dessin-' + datetime + '.png';
        break;
      case 'image/jpeg':
        medName = 'photo-' + datetime + '.jpeg';
        break;
    }
    formData.append("medName", medName);
    try {
      const response = await API.post('/media', formData);
      onMediaCreated(response.data)
    } catch (error) {
      swalAlert(error)
    }
  }
  return (
    <div className='MediaAcquisitionToolbar text-center' style={{ opacity: disabled ? '0.5' : '1' }}>
      <div className="btn-group " role="group">
        <MemoEditor iconsSize={iconsSize} name="" memo="" onSave={saveMemo} disabled={disabled} />
        <HandDrawnEditor image="" iconSize={iconsSize} onSave={saveBlob} disabled={disabled} />
        <VoiceRecorder iconsSize={iconsSize} onSave={saveBlob} disabled={disabled} />
        <CameraCapture onSave={saveBlob} disabled={disabled} />
      </div>
    </div>
  )
}
export default MediaAcquisitionToolbar;