import React, { useState, useEffect, Children } from 'react';
import { useTranslation } from 'react-i18next';
import { BiShow, BiHide } from "react-icons/bi";
import { Form, Button, Alert, InputGroup } from 'react-bootstrap'; // Importez les composants React Bootstrap nécessaires
import PasswordChecker from './../helpers/PasswordChecker/PasswordChecker'
import './user-pwd.css';
// import './../../css/forms.css';
import API from '../../api';
import CustomAlert from './../helpers/CustomAlert/CustomAlert';
import { debug } from './../../utils';
function UserPwd({ onSuccess = null, onTabSwitch, children, options }) {
  const { t } = useTranslation();
  const [data, setData] = useState({ password: '', confirmPassword: '', token: options.recoveryToken })
  const [step, setStep] = useState(1)
  const [inputPasswordType, setInputPasswordType] = useState('password')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [submission, setSubmission] = useState({ status: '', message: '' });
  const handleFormSumbit = async (event) => {
    try {
      const response = await API.post('/user/updatePwd', data)
      setSubmission({ status: 'success', message: t(response.data.message) })
      setStep(2);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      setFormSubmitted(false);
      setSubmission({ status: 'error', message: t(error.response.data.error.message) })
    }
  }
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  return (
    <div className='user-form-container '>
      <Form onSubmit={(e) => { e.preventDefault() }}>
        <h1 className=''>Modification de votre mot de passe</h1>
        {step === 1 &&
          <>
            <Form.Group className="form-group mb-1 pb-0">
              <label className="form-group-label" required>Nouveau mot de passe : </label>
              <InputGroup className='mb-1'>
                <Form.Control
                  type={inputPasswordType}
                  size='sm'
                  autoComplete='new-password'
                  value={data.password}
                  onChange={(e) => { setData({ ...data, password: e.target.value }); }}
                  disabled={submission.status == 'pending'}
                />
                <Button size='sm' variant="outline-secondary" onClick={() => { setInputPasswordType(inputPasswordType == 'password' ? 'text' : 'password') }}>
                  {inputPasswordType === 'password' ? <BiShow /> : <BiHide />}
                </Button>
              </InputGroup>
              <PasswordChecker password={data.password} isValid={v => setPasswordIsValid(v)} />
            </Form.Group>
            <CustomAlert input={submission} />
            <Form.Group className="form-group-submit">
              <Button variant="warning" type="button" disabled={!passwordIsValid || submission.status == 'pending' || submission.status == 'success'} onClick={handleFormSumbit}>
                Sauvegarder
              </Button>
            </Form.Group>
            <p className='small pt-4 pb-5'>
              <span className=' d-inline-block pointer' onClick={e => onTabSwitch('')}>Annuler</span>
            </p>
          </>
        }
        {step === 2 &&
          <div className='py-5'>
            <CustomAlert input={{ status: 'success', message: 'Votre mot de passe a eté modifié.' }} />
            <p className="pb-5">
              <button className="btn btn-warning btn-sm" onClick={e => onTabSwitch('close')}>Fermer</button>
            </p>
          </div>
        }

      </Form >
    </div >
  );
}
export default UserPwd;
