import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TreeFormFeatures from './form-tabs/TreeFormFeatures';
import { useProjectMapContext } from './../../../hooks/useProjectMapContext';
import useItemsService from './../../../hooks/useItemsService';
import { HtmlElement } from './../../../../helpers/HtmlElements/HtmlElements';
import { debug } from './../../../../../utils';
import API from './../../../../../api';
import './../../../../../css/nav-tabs.css'
import './css/masonry.scss';
import ResponsiveNavTabs from './../../../../helpers/ResponsiveNavTabs/ResponsiveNavTabs';
const TreesGroupForm = ({ item = null }) => {
  const {
    userCan,
    setItemSelected,
  } = useProjectMapContext();
  const {
    save_item, update_item,
  } = useItemsService();
  const project = useSelector(state => state.project);
  const [activeTab, setActiveTab] = useState(0);
  const [itemCopy, setItemCopy] = useState({ initialized: false, meta: {} });
  const nav_tabs = [
    'Caratéristiques',
    'Visites',
    'Tâches',
    'Photos',
    'Documents',
    'Debug'
  ];
  useEffect(() => {
    //    const itemCopy = { ...item };
    setItemCopy({ ...item });
  }, [item]);
  const handleChange = (obj) => {
    if (obj.target) {
      const { name, value } = obj.target;
      const parts = name.split('.');

      let newObj
      if (parts.length == 1) {
        newObj = { ...itemCopy, [parts[0]]: value };
      } else {
        newObj = { ...itemCopy, [parts[0]]: { ...itemCopy[[parts[0]]], [parts[1]]: value } }

      };
      setItemCopy(prevItem => ({ ...prevItem, ...newObj }));
    } else {
      setItemCopy(prevItem => ({ ...prevItem, ...obj }));
    }
  };
  const submit = async () => {
    let response = null;
    if (!itemCopy.itId || itemCopy.itId == '') {
      response = await save_item(itemCopy);
    } else {
      response = await update_item(itemCopy);
    }
    setItemSelected(null);
  }
  return (
    <div id="TreeFrom" className='d-flex flex-column h-100 flex-grow-1'>
      {false && <ResponsiveNavTabs tabNames={nav_tabs} activeTab={activeTab} setActiveTab={setActiveTab} >
      </ResponsiveNavTabs>}
      {userCan('editItem') &&
        <div className='text-end'>
          <HtmlElement.submit text="Sauvegarder" onClick={submit} />
        </div>
      }
      <div className={`tab-content flex-grow-1`} >
        <div className={`tab-pane fade show ${activeTab == 0 ? 'active' : ''}`} >
          <HtmlElement.formGroup name="itFeatures.childrenCount" value={itemCopy.itFeatures?.childrenCount} label="Nombre de sujets" size="lg" onChange={handleChange} />
          {false && <TreeFormFeatures item={itemCopy} onChange={handleChange} />}
        </div>
        <div className={`tab-pane fade show ${activeTab === 1 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 2 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 3 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 4 ? 'active' : ''}`} >
        </div>
        <div className={`tab-pane fade show ${activeTab === 5 ? 'active' : ''}`} >
          {debug(itemCopy)}
        </div>

      </div>
      {debug(itemCopy)}
    </div>
  );
};
export default TreesGroupForm;
