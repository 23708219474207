import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { debug } from '../../../utils';
import { AxiosError } from 'axios'
const CustomAlert = ({ input, variant = '', onClose = null }) => {
  const [variantCopy, setVariantCopy] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (input) {

      if (typeof input == 'string') {

        setVariantCopy(variant == '' ? 'danger' : variant);
        setMessage(input);
      }

      if (input instanceof Error) {
        setVariantCopy('danger');
        setMessage(input.message);
      }
      if (input instanceof AxiosError) {
        setVariantCopy('danger');
        setMessage(input.response.data.error.message);
      }
      if (input.status && (input.status === 200 || input.status === 'success')) {
        setVariantCopy('success');
        setMessage(input.message);
      }
      if (input?.status === 'error') {
        setVariantCopy('danger');
        setMessage(input.message);
      }
    } else {
      setVariantCopy('');
    }
  }, [input])
  return (
    <>
      {variantCopy != '' &&
        <Alert variant={variantCopy} dismissible={onClose !== null} className='small py-2 px-3' onClose={onClose}>
          {message}
        </Alert>
      }
    </>
  );

};

export default CustomAlert;
