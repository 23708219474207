//MapContextMenu.js
import React, { useEffect, useState, useRef } from 'react';
import useItemsService from './../hooks/useItemsService';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { debug } from '../../../utils';
import { PiTreeBold, PiLineSegmentBold, PiPolygon } from "react-icons/pi";

const MapContextMenu = ({ options = {}, onClick }) => {
  const ref = useRef();
  const { setMapContextMenuOptions, handleMapMenuClick, userCan } = useProjectMapContext();
  const { create_item } = useItemsService();



  const add_item = (itType) => {
    create_item({
      itType: itType,
      itName: '',
      itParentId: options.activeLayerId,
      itGeometry: options.latlng,
      itStatus: 'active',
    });
  }
  return (
    userCan('editItem') &&
    <div id="MapContextMenu" className={`contextMenu`} style={{ left: options.left, top: options.top }} ref={ref}>
      <div className={`header d-flex justify-content-between px-2 pointer`}>
        <span></span>
        <span onClick={e => setMapContextMenuOptions({ show: false })}>&times;</span>
      </div>
      <div className='body'>
        <ul>
          <li className='pointer' onClick={e => add_item('tree')} ><span className='me-2'><PiTreeBold /></span>Ajouter un sujet</li>

          <li className='pointer' onClick={e => add_item('marker')} ><span className='me-2'><PiTreeBold /></span>Ajouter un marker</li>
        </ul>
      </div>
      <div className='footer'>
      </div>
    </div >
  );
};

export default MapContextMenu;