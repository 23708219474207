import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { HtmlElement } from '../../helpers/HtmlElements/HtmlElements';
import { FaPlus } from "react-icons/fa";
import { swalInput } from 'utils';
import { useProjectMapContext } from '../hooks/useProjectMapContext';
import useItemsService from '../hooks/useItemsService';

const LayerSelector = ({ selectedId = '', name = 'itParentId', onChange, allowCreate = false, required = false }) => {

  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);
  const [layersOptions, setLayersOptions] = useState([]);
  const [value, setValue] = useState(selectedId);
  const layersCount = useRef(project.layers.length);
  const { userCan } = useProjectMapContext();
  const { save_item } = useItemsService();
  useEffect(() => {
    if (value !== selectedId) {
      setValue(selectedId)
    }
  }, [selectedId])
  useEffect(() => {
    if (project.layers.length > layersCount.current) { // creation d'un layer depuis ce composant
      // alert(project.layers.length + ' = ' + layersCount.current)
      onChange({ [name]: project.layers[0].itId })
    }
    setLayersOptions(project.layers.map(layer => ({
      value: layer.itId,
      label: layer.itName
    })));
    layersCount.current = project.layers.length;
  }, [project.layers])
  const createLayer = async () => {
    const response = await swalInput({ title: 'Nom du nouveau claque' })
    if (response) {
      save_item({ itType: 'layer', itProjId: project.projId, itName: response, itFeatures: { color: '', opacity: "0.2" } })
    }
  }
  return <div className='form-group'>
    <label className={`form-group-label ${required ? 'required' : ''}`}>Calque :</label>
    <div className='input-group'>
      <HtmlElement.select
        value={value}
        name={name}
        onChange={onChange}
        required={true}
        size='sm'
        options={[...layersOptions]}
        className='mb-0'
        allowEmpty={false}
        rows={6}
      />
      {(allowCreate && userCan('editLayers')) &&
        <button className='pointer btn btn-sm b btn-outline-primary' onClick={createLayer}>
          <FaPlus size={14} style={{ position: 'relative', top: '-1px' }} />
        </button>}
    </div>

  </div>
}
export default LayerSelector;