import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from './../api';
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { dispatch, getState }) => {
    const state = getState().user;
    if (state.isSignedIn) {
      try {
        const response = await API.get('/user');
        if (response.data.userStatus !== 'active') {
          dispatch(clearUser());
        } else {
          return response.data;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur", error);
      }
    }
  }
);
export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async (_, { dispatch }) => {
    try {
      const response = await API.get('/user/details');
      dispatch(setUser({ details: response.data }));
      return response.data;
    } catch (error) {
      console.error("Erreur lors du chargement des détails de l'utilisateur", error);
      throw error;
    }
  }
);
const userSlice = createSlice({
  name: 'user',
  initialState: {
    isSignedIn: localStorage.getItem('signedIn') ? true : false,
    // Ajoute les champs initiaux nécessaires ici
  },
  reducers: {
    setUser: (state, action) => {
      Object.assign(state, action.payload);
      state.isSignedIn = true;
      localStorage.setItem('signedIn', 1);
    },
    updateUser: (state, action) => {
      Object.assign(state, action.payload);
    },
    clearUser: (state) => {
      Object.keys(state).forEach((key) => state[key] = null);
      state.isSignedIn = false;
      localStorage.removeItem('signedIn');
      API.post('/user/signOut');
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {

      if (action.payload) {
        Object.assign(state, action.payload);
      }
    });
  },

});

export const { setUser, updateUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
