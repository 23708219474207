import Picture from 'components/helpers/Picture/Picture';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiTrashAlt } from "react-icons/bi";
import { PiPaintBrushLight } from "react-icons/pi";
import { BsPencil } from "react-icons/bs";
import { TfiNewWindow } from "react-icons/tfi";
import { debug, formatDate, isDev, swalAlert, swalInput } from 'utils';
import API from 'api';
// import MediaViewer from 'components/helpers/MediaViewer/MediaViewer';
import Uploader from 'components/helpers/Uploader/Uploader';
import MediasList from 'components/medias/MediasList';
const TreeFormMedias = ({ item, onChange }) => {
  // const [mediaViewerIndex, setMediaViewerIndex] = useState(-1);
  const user = useSelector((state) => state.user);
  const handleNameChange = async (index) => {
    const extension = item.medias[index].medName.split('.').pop(); // Extraire l'extension
    const nameWithoutExtension = item.medias[index].medName.replace(new RegExp(`.${extension}$`), ''); // Supprimer l'extension du nom pour l'édition
    const newName = await swalInput({
      title: 'Modifier le nom du fichier',
      value: nameWithoutExtension,
      placeholder: 'Entrez le nouveau nom du fichier'
    });
    if (newName) {
      const mediasCopy = [...item.medias];
      try {
        const response = await API.put(`/media/${mediasCopy[index].medId}`, { medName: `${newName}.${extension}` });
        mediasCopy[index] = response.data;
        onChange({ medias: mediasCopy })
      } catch (e) {
        swalAlert(e);
      }
    }
  };
  const uploaderinputProps = {}
  const onUploadStart = (data) => {

  }
  const onUploadSuccess = async (data) => {
    const mediasCopy = [...item.medias];
    const media = ({
      'medName': data.name,
      'medPath': data.path,
      'medMimeType': data.type,
      'medParentId': item.itId,
    });
    try {
      const response = await API.post('/media', media);
      mediasCopy.push(response.data);
      onChange({ medias: mediasCopy })
    } catch (error) {
      swalAlert(error);
    }
  }
  const deleteMedia = (index) => {
    const mediasCopy = [...item.medias];
    mediasCopy[index].medStatus = 'deleted';
    onChange({ medias: [...mediasCopy] })
  }
  const onUploadFailure = (data) => {
  }
  return (
    <>
      <div>
        <MediasList medias={item?.medias} onChange={medias => onChange({ medias: medias })} parentId={item.itId} />
        <div>
          <Uploader inputProps={uploaderinputProps} size='small' onUploadStart={onUploadStart} onUploadSuccess={onUploadSuccess} onUploadFailure={onUploadFailure} />
        </div>
      </div >
      {debug(item?.medias)}
    </>
  )
}
export default TreeFormMedias;



