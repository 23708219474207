import React, { useEffect, useState, useContext } from 'react';
import { DeviceContext } from './../../../DeviceContext'
import { useSelector, useDispatch } from 'react-redux';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { PiTreeFill, PiNoteFill } from "react-icons/pi";
import { FaMapMarkerAlt, FaLocationArrow, FaRegStickyNote, FaPencilAlt, FaMicrophone } from 'react-icons/fa';
import { updateProject } from './../../../redux/projectSlice'; // Importe les actions Redux
// Hooks
import useItemsService from './../hooks/useItemsService';
import useCurrentLocation from './../hooks/useCurrentLocation';
import { debug, swalAlert } from 'utils';
import './ui-mode-field.css';

import CustomAlert from './../../helpers/CustomAlert/CustomAlert';
import MapWidget from './../../helpers/MapWidget/MapWidget';
import MediaAcquisitionToolbar from 'components/helpers/MediaAcquisitionToolbar/MediaAcquisitionToolbar';
const UiModeField = () => {
  const dispatch = useDispatch();
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const [initialized, setInitialized] = useState(false);
  const [iconsSize, setIconsSize] = useState([50]);
  useEffect(() => {
    setIconsSize((isTablet || isDesktop) ? 30 : 30);
  },
    [isMobile, isTablet, isDesktop]);
  const { create_item } = useItemsService();
  let [location, locationError] = useCurrentLocation();
  if (locationError) {
    console.error(locationError);
    location = [49.8696192, 1.1010048]
  }
  const { } = useProjectMapContext();
  useEffect(() => { //
    window.setTimeout(() => setInitialized(true), 500);
  }, [])
  const project = useSelector(state => state.project);
  const config = useSelector(state => state.config)
  const handleMediaCreated = (media) => {
    const medias = [...project.medias] || [];

    medias.push(media);
    dispatch(updateProject({ medias: medias }))
    if (media.medMimeType != 'image/jpeg') {
      swalAlert('Elément ajouté aux pièces jointes du projet')
    }

  }
  return (
    <div id="UiModeField" className='project-map-inner'>
      <div className=''>
        {(locationError && false) ? (
          <div style={{ maxWidth: '96%', width: '450px', margin: '15% auto' }} className='text-center'>
            <CustomAlert input={{ status: 'error', message: "Vous devez autoriser l'accès à la position du périphérique pour utilsier le mode terrain" }} /></div>
        )
          :
          (<div className=' pt-3'>
            <div className='text-center'>
              <div className='mb-5'>
                <h5><FaLocationArrow className='me-2' />
                  {location[0]} / {location[1]}</h5>
              </div>
              <div className='mb-5'>
                <h5 className='text-center'>PROJET</h5>
                <div className="btn-group " role="group">
                  <MediaAcquisitionToolbar
                    onMediaCreated={media => handleMediaCreated(media)}
                    medParentId={project.projId}
                  />
                </div>
              </div>
              <div className='mb-5'>
                <h5 className='text-center'>AJOUTER UN ELEMENT</h5>
                <div className="btn-group " role="group">
                  <button
                    className='btn btn-lg btn-outline-primary py-2'
                    onClick={e => create_item({ itType: 'tree', itGeometry: [...location] })}>
                    <PiTreeFill size={iconsSize} />
                  </button>
                  <button className='btn btn-lg btn-outline-primary py-2'
                    onClick={e => create_item({ itType: 'marker', itGeometry: [...location] })}>
                    <FaMapMarkerAlt size={iconsSize} />
                  </button>
                </div>
              </div>
              <div className='mb-5' style={{ height: '400px' }}>
                {initialized && <MapWidget position={location} />}
              </div>
              <div className='m-5'>
              </div>
            </div>
          </div>
          )}
      </div>
    </div>
  );
};
export default UiModeField;
