// CustomLayers.js
import CustomPolyline from './CustomPolyline';
import CustomMarker from './CustomMarker';
import CustomMarkerTree from './CustomMarkerTree';
export const CustomLayers = {
  marker: ({ item }) => (
    <CustomMarker item={item} key={item.itId} />
  ),
  tree: ({ item }) => (
    <CustomMarkerTree item={item} key={item.itId} />
  ),
  line: ({ item }) => (
    <CustomPolyline item={item} key={item.itId} />
  ),
  alignment: ({ item }) => (
    <CustomPolyline item={item} key={item.itId} />
  ),

  // Ajoutez d'autres boutons selon vos besoins
};
