import React, { useEffect, useState, useRef } from 'react';
import { debug } from '../../../utils';
import './input-autocomplete.css';
const InputAutocomplete = ({
  suggestionsSrc,
  value,
  onSuggestionSelect,
  onChange,
  name,
  filterMode = 'startsWith',
  displayKey,
  formatSuggestion,
  inputRef = null,
  suggestionsOnFocus = false,
  placeholder = '',
  inputClassName = '',
  debug: debug_on = false
}) => {
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const suggestionsContainerRef = useRef(null);
  const [filteredSuggestionsSrc, setFilteredSuggestionsSrc] = useState([]);
  const handleInputChange = (event) => {
    const { value } = event.target;
    setDisplaySuggestions(value !== '' || suggestionsOnFocus);
    onChange(event);
    const normalizedValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (filterMode === 'startsWith') {
      const index = suggestionsSrc.findIndex(
        (item) => item[displayKey].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(normalizedValue) === 0
      );
      setSelectedSuggestionIndex(index);
    } else if (filterMode === 'includes') {
      const filteredSuggestions = suggestionsSrc.filter(
        (item) => item[displayKey].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normalizedValue)
      );
      setFilteredSuggestionsSrc(filteredSuggestions);
      setSelectedSuggestionIndex(-1);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    if (suggestion && suggestion[displayKey]) {
      onSuggestionSelect(name, suggestion);
    }
    setDisplaySuggestions(false);
  };
  useEffect(() => {
    if (displaySuggestions && value) {
      const normalizedValue = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      if (filterMode === 'startsWith') {
        const index = suggestionsSrc.findIndex(
          (item) => item[displayKey].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(normalizedValue) === 0
        );
        setSelectedSuggestionIndex(Math.max(0, index));
        scrollToSelectedSuggestion(index);
      } else if (filterMode === 'includes') {
        setSelectedSuggestionIndex(-1);
      }
    }
  }, [displaySuggestions]);

  const handleOnFocus = () => {
    setDisplaySuggestions(suggestionsOnFocus);
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      setDisplaySuggestions(false);
    }, 200);
  };

  const scrollToSelectedSuggestion = (index) => {
    if (index >= 0 && suggestionsContainerRef.current) {
      const suggestionElement = suggestionsContainerRef.current.children[index];
      if (suggestionElement) {
        suggestionsContainerRef.current.scrollTop = suggestionElement.offsetTop;
      }
    }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (displaySuggestions) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex <= 0 ? suggestionsSrc.length - 1 : prevIndex - 1
          );
        } else {
          setDisplaySuggestions(true);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (displaySuggestions) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex >= suggestionsSrc.length - 1 ? 0 : prevIndex + 1
          );
        } else {
          setDisplaySuggestions(true);
        }
        break;
      case 'Enter':
        event.preventDefault();
        setDisplaySuggestions(false);
        switch (filterMode) {
          case 'startsWith':
            if (suggestionsSrc.length > 0) {
              handleSuggestionClick(suggestionsSrc[selectedSuggestionIndex]);
            }
            break;
          case 'includes':
            if (filteredSuggestionsSrc.length > 0) {
              handleSuggestionClick(filteredSuggestionsSrc[selectedSuggestionIndex]);
            }
            break;
        }
        break;
      case 'Escape':
        setDisplaySuggestions(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    scrollToSelectedSuggestion(selectedSuggestionIndex);
  }, [selectedSuggestionIndex]);
  return (
    <div className='autocomplete-input'>
      <input
        ref={inputRef}
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        value={value || ''}
        className={`form-control ${inputClassName}`}
      />
      {displaySuggestions && (
        <div className="autocomplete-container" ref={suggestionsContainerRef}>
          {(filterMode === 'startsWith' ? suggestionsSrc : filteredSuggestionsSrc).map((item, index) => (
            <div
              key={index}
              className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
              onClick={() => handleSuggestionClick(item)}
            >
              {formatSuggestion(name, item)}
            </div>
          ))}
        </div>
      )}
      {debug_on && debug(filteredSuggestionsSrc)}
    </div>

  );
};

export default InputAutocomplete;
