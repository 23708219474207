import React, { useEffect, useState, useContext, useRef } from 'react';
import { DeviceContext } from './../../../DeviceContext'
import { useSelector, useDispatch } from 'react-redux';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import useItemsService from './../hooks/useItemsService';
import useMapService from './../hooks/useMapService';
import MapContextMenu from './../menus/MapContextMenu';
import ItemContextMenu from './../menus/ItemContextMenu';
import ProjectSidebar from './../toolbars/ProjectSidebar';
import {
  MapContainer, TileLayer, useMap, useMapEvents, ZoomControl, FeatureGroup, Circle, Polygon, Line
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import CustomLayerGroup from './../items/layers/CustomLayerGroup';
import { useNavigate } from 'react-router-dom';
import { debug, swalAlert, isDev } from 'utils';
import 'leaflet/dist/leaflet.css';
import './../css/project-map.css';
import './../css/toolbars.css';
import './../../../css/context-menus.css';
import TestCtrl from '../items/test/TestCtrl';
const UiModeDesktop = () => {
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const { } = useMapService();
  const {
    mapType, mapRef, mapEditRef, setMapRef, itemSelected, itemContextMenuOptions,
    mapContextMenuOptions,
  } = useProjectMapContext();
  const project = useSelector(state => state.project);
  const config = useSelector(state => state.config)
  return (
    <div className='main d-flex '>
      <div className='sidebar flex-grow-0'>
        <ProjectSidebar mapContextMenuOptions={itemSelected} />
      </div>
      <div className='map'>
        <MapContainer
          key={project?.projId}
          center={project.projGeometry}
          zoom={18}
          style={{ height: "calc(100vh - 100px)", width: "100%" }}
          scrollWheelZoom={true}
          zoomControl={false}
          whenReady={(map) => {
            setMapRef(map.target); // Initialise la référence de la carte avec l'instance de la carte
          }}
        >
          {mapType?.id && (
            (mapType.provider === 'Google' && (
              <ReactLeafletGoogleLayer apiKey={mapType.apikey} type={mapType.type} />
            )) ||
            (mapType.url !== '' && (
              <TileLayer
                url={mapType.url}
              />
            ))
          )}
          {project.layers.map((layer, idx) => (
            <CustomLayerGroup layer={layer} key={idx} />
          ))}
          <MapEventsHandler
            onEvent={e => { }}
          ></MapEventsHandler>
          <ZoomControl position="bottomright" />
        </MapContainer>
        {mapContextMenuOptions?.show === true && <MapContextMenu options={mapContextMenuOptions} />}
        {itemContextMenuOptions?.show === true && <ItemContextMenu options={itemContextMenuOptions} />}
      </div>
    </div >
  );
};
export default UiModeDesktop;


function MapEventsHandler({ onEvent }) {
  const {
    mapRef
  } = useProjectMapContext();
  useEffect(() => {
    // Liste des noms d'événements à écouter.
    const events = [
      'click',
      'dblclick',
      'mousedown',
      'mouseup',
      'mouseover',
      'mouseout',
      'mousemove',
      'keypress',
      'keydown',
      'keyup',
      'zoomstart',
      'zoomend',
      'zoomlevelschange',
      'resize',
      'dragstart',
      'drag',
      'dragend',
      'moveend',
      'contextmenu'
    ];
    // Fonction générique pour gérer les événements
    const genericEventHandler = (event) => onEvent(event);
    // Attacher dynamiquement tous les gestionnaires d'événements
    events.forEach(eventName => {
      mapRef.current.on(eventName, genericEventHandler);
    });
    // Nettoyer en supprimant les gestionnaires d'événements
    return () => {
      events.forEach(eventName => {
        mapRef.current.off(eventName, genericEventHandler);
      });
    };
  }, [mapRef, onEvent]); // S'assurer de refaire cela uniquement si la carte ou onEvent change
  return null; // Ce composant ne rend rien par lui-même
}
/*
style={{ height: "calc(100vh - 100px)", width: "100%", pointerEvents: 'none' }}
              onEdited={this._onEditPath}
              onCreated={this._onCreate}
              onDeleted={this._onDeleted}
*/