import { useState } from 'react';

const useFormHandler = (initialItem) => {
  const [itemCopy, setItemCopy] = useState(initialItem);
  const handleChange = (obj) => {
    if (obj.target) {
      const { name, value } = obj.target;
      const parts = name.split('.');
      setItemCopy(prevItem => {
        let tempObj = { ...prevItem };
        parts.reduce((acc, part, index) => {
          if (index === parts.length - 1) {
            acc[part] = value;
            return;
          }
          acc[part] = acc[part] || {};
          return acc[part];
        }, tempObj);
        return tempObj;
      });
    } else {
      setItemCopy(prevItem => ({ ...prevItem, ...obj }));
    }
  };
  return [itemCopy, handleChange];
};

export default useFormHandler;
