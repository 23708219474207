// projectSlice.js
import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    layers: [],
    metaVisibility: [
      { id: 'rpz', name: 'ZPR', tooltip: 'Zone de protection racinaire', metapath: 'dendrometry/rpz', visible: true },
      { id: 'crownSpan', name: 'Houpier', tooltip: 'Houpier', metapath: 'dendrometry/crownSpan', visible: true }
    ],
    filters: [],
    filtering_operators: []
  },
  reducers: {
    setProject(state, action) {
      const payload = { ...action.payload };
      payload.metaVisibility = [
        { id: 'rpz', name: 'ZPR', tooltip: 'Zone de protection racinaire', metapath: 'dendrometry/rpz', visible: true, stroke: '#8B4513', opacity: 0.2 },
        { id: 'crownSpan', name: 'Houpier', tooltip: 'Houpier', metapath: 'dendrometry/crownSpan', visible: true, stroke: 'cyan', opacity: 0.1 }
      ];
      payload.filters = [];
      payload.items = apply_items_filters(payload.items, payload.filters, payload.filtering_operators)
      return payload;
    },
    updateProject(state, action) {
      if (action.payload.items) {
        action.payload.items = apply_items_filters(action.payload.items, state.filters, state.filtering_operators);
      }
      if (action.payload.filters) {
        action.payload.items = apply_items_filters(state.items, action.payload.filters, state.filtering_operators);
      }
      return {
        ...state,
        ...action.payload
      };
    },
    clearProject(state) {
      return null;
    },
  },
});
const operatorFunctions = {
  "=": (a, b) => a === b,
  "<": (a, b) => a < b,
  "<=": (a, b) => a <= b,
  ">": (a, b) => a > b,
  ">=": (a, b) => a >= b,
  "include": (a, b) => a.includes(b),
  "between": (a, b) => a >= b[0] && a <= b[1],
};
const apply_items_filters = (items, filters, operators) => {
  if (!items) {
    return [];
  }
  const getItemValue = (item, key) => {
    let value = item[key] !== undefined ? item[key] : (item.meta && item.meta[key] !== undefined ? item.meta[key] : null);
    if (value) {
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }
    return value;
  };
  const itemsWithMatchFilter = items.map(item => {
    if (filters.length === 0) {
      return { ...item, match_filters: true };
    } else if (item.itType === 'tree') { // Traitement spécifique pour les items de type 'tree'
      const doesMatch = filters.every(filter => {
        let itemValue = getItemValue(item, filter.key);
        const filterValue = filter.operator === 'between' ? filter.value : filter.value.toLowerCase();
        const operatorObj = operators.find(op => op.value === filter.operator);
        const operatorFunc = operatorObj ? operatorFunctions[operatorObj.value] : null;
        return !filter.active || (operatorFunc && operatorFunc(itemValue, filterValue) === true);
      });
      return { ...item, match_filters: doesMatch };
    } else {
      return { ...item };
    }
  });
  return itemsWithMatchFilter;
};
export const { setProject, updateProject, setMapRef, clearProject } = projectSlice.actions;
export default projectSlice.reducer;