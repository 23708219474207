// ProjectsAdminHomeBloc.js
import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaRegPlusSquare } from 'react-icons/fa';
import API from 'api'
const ProjectsAdminHomeBloc = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  useEffect(() => {
    get_projects();
  }, []);
  useEffect(() => {

    if (selectedCustomer?.projects.length > 0) {
      setSelectedProject(selectedCustomer.projects[0])
    }
  }, [selectedCustomer]);

  useEffect(() => {

  }, [selectedProject]);

  const get_projects = async () => {
    const response = await API.get('/admin/projects');
    const customersObj = {};
    response.data.forEach(data => {
      if (!customersObj[data.customer.custId]) {
        customersObj[data.customer.custId] = { ...data.customer, projects: [] };
      }
      customersObj[data.customer.custId].projects.push(data);
    });
    const customersArray = Object.values(customersObj);
    const sortedCustomers = customersArray.sort((a, b) => {
      if (a.custName < b.custName) return -1;
      if (a.custName > b.custName) return 1;
      return 0;
    });
    setCustomers(sortedCustomers);
  };

  const getProjet = () => {

    navigate(`/project/map/${selectedProject.projId}`)
  }
  /*
  const canCreateProject = () => {
    return user.userRoles?.Projects == 'admin' &&
      (user?.details?.projects.length < user.details?.customer?.plan.planMaxProjects)
  }
  */
  return (
    user?.userRoles?.Projects && user?.userRoles?.Projects == 'admin' &&
    <div id="ProjectsAdminHomeBloc" className="col-md-4 mb-3" >
      <div className="card h-100">
        <div className="card-body">
          <div className='d-flex justify-content-between'>
            <h5 className="card-title ">Projets clients</h5>
            {/* && canCreateProject() &&
              <Link to="/project/map/new" title="Créer un projet"><FaRegPlusSquare size={18} /></Link>
            */}
          </div>
          <div className="card-text">
            <div className="card-text">
              <div className='mb-3'>
                <label className='form-group-label'>Clients :</label>
                <select
                  className='form-control form-control-sm'
                  name="custId"
                  value={selectedCustomer ? selectedCustomer.custId : ''}
                  onChange={e => { setSelectedCustomer(customers.find(c => c.custId === e.target.value)); }}
                >
                  <option value="">&gt; sélectionner un client</option>
                  {customers.map(c => (
                    <option key={c.custId} value={c.custId}>{c.custName}</option>
                  ))}
                </select>
              </div>
              {selectedCustomer && <div className='mb-3'>
                <label className='form-group-label'>Projets :</label>
                <div className='input-group'>
                  <select
                    className='form-control form-control-sm'
                    name="projId"
                    value={selectedProject ? selectedProject.projId : ''}
                    onChange={e => { setSelectedProject(selectedCustomer.projects.find(p => p.projId === e.target.value)) }}
                  >
                    {selectedCustomer.projects.map(p => (
                      <option key={p.projId} value={p.projId}>{p.projName} - {p.projAddress}</option>
                    ))}
                  </select>
                  <button className='btn btn-sm btn-outline-primary' onClick={getProjet} disabled={selectedProject === null}>OK</button>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectsAdminHomeBloc;
