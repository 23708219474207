import React, { useState, useEffect, useRef } from 'react';
import Picture from 'components/helpers/Picture/Picture';
import DrawingCanvas from 'components/helpers/DrawingCanvas/DrawingCanvas';
import API from 'api';
import { debug, file, swalAlert } from 'utils';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import './media-viewer.css';
const MediaViewer = ({ initialIndex = 0, onClose, medias }) => {
  const blobUrl = useRef('');
  const [mediaContent, setMediaContent] = useState("");
  const [fetching, setFetching] = useState(true);
  const [media, setMedia] = useState(null);
  const [index, setIndex] = useState(initialIndex);
  /*
    useEffect(() => {
      if (medias && medias.length > 0) {
        setMedia(medias[index]);
      } else {
        setMedia(null);
      }
    }, [index, medias]);
    */
  useEffect(() => {
    setFetching(true)
  }, [index]);
  useEffect(() => {
    if (fetching == true) {
      const fetchFileContent = async () => {
        try {
          if (medias && medias.length > 0) {
            const media = medias[index];
            setMedia(media);
            // console.clear();
            const response = await file(media.medId);
            blobUrl.current = response;

            switch (media.medMimeType) {
              case 'text/plain':
                setMediaContent(<textarea className='form-control' readOnly value={response} />);
                break;
              case 'image/jpeg':
              case 'image/png':

                setMediaContent(<DrawingCanvas src={response} mimeType={media.medMimeType} onChange={handleImageChange} />);
                break;
              case 'audio/mpeg':
              case 'audio/mp4':

                setMediaContent(<audio controls src={response} />);
                break;
              case 'application/pdf':
                setMediaContent(
                  <iframe
                    src={response}
                    style={{ width: '100%', height: '100%' }}
                    frameBorder="0"
                  >
                  </iframe>
                );
                break;
              default:
                setMediaContent(<p>Unsupported media type</p>);
                break;
            }
            setFetching(false)
            //window.setTimeout(() => {  }, 500)
          }
        } catch (error) {
          setMedia(prevMedia => ({ ...prevMedia, content: "Erreur de chargement du contenu." }));
        }
      };
      fetchFileContent();
    }
  }, [fetching])
  const handleImageChange = async (imageDataUrl) => {
    fetch(imageDataUrl)
      .then(res => res.blob())
      .then(async blob => {
        const formData = new FormData();
        formData.append("file", blob); // Ajoute le Blob
        try {
          const response = await API.post('/media/file/' + media.medId, formData);
          swalAlert('Sauvegarde effectuée')
        } catch (error) {

          swalAlert(error)
        }
      })
      .catch(error => console.error('Erreur lors de la conversion de l\'image en Blob:', error));
  }
  return (
    <div className="mediaViewer">
      {(fetching == false) && <div className="mediaContent">
        {mediaContent}
      </div>}
      <div className='row controls controls-top' >
        <div className='col-4 text-start overflow-hidden'>
          <span className='ps-3 pt-2 fs-8 d-inline-'> {media?.medName}</span>
        </div>
        <div className='col-4 text-center'></div>
        <div className='col-4 text-end'>
          <span className='ms-3 pointer pt-2' onClick={onClose}> <MdClose size="24" /></span>
        </div>
      </div>
      <div className='row controls controls-bottom' >
        <div className='col-4 text-start'></div>
        <div className='col-4 text-center'>
          <span className='mx-2 pointer' onClick={() => setIndex(i => i - 1 > -1 ? i - 1 : medias.length)}><MdNavigateBefore size="50" /></span>
          <span>{index}</span>
          <span className='mx-2 pointer' onClick={() => setIndex(i => i + 1 < medias.length - 1 ? i + 1 : 0)}><MdNavigateNext size="50" /></span>
        </div>
        <div className='col-4 text-end'>
        </div>
      </div>
    </div >
  );
};
export default MediaViewer;