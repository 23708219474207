import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaRegPlusSquare } from 'react-icons/fa';
const ProjectsHomeBloc = () => {
  const user = useSelector((state) => state.user);
  const canCreateProject = () => {
    return user.userRoles?.Projects == 'admin' &&
      (user?.details?.projects.length < user.details?.customer?.plan.planMaxProjects)
  }
  return (
    user?.userRoles?.Projects &&
    <div id="ProjectsHomeBloc" className="col-md-4 mb-3">
      <div className="card h-100">
        <div className="card-body">
          <div className='d-flex justify-content-between'>
            <h5 className="card-title ">Mes projets</h5>
            {canCreateProject() &&
              <Link to="/project/map/new" title="Créer un projet"><FaRegPlusSquare size={18} /></Link>
            }
          </div>
          <div className="card-text">
            {user.details.projects.map((project, idx) => (
              <dl key={idx}>
                <dt><Link to={`/project/map/${project.projId}`}>{project.projName}</Link></dt>
                <dd className='fs-8'>
                  Créé par {project.projCreatedBy}<br />
                  {project.projAddress}
                </dd>

              </dl>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsHomeBloc;

/*

FaRegPlusSquare 
*/