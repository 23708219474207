// Header.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header_User from './../user/Header_User';

import { isDev } from './../../utils';
import { Link } from 'react-router-dom';
import './header.css';
import './frontoffice-header.css';
import API from './../../api';

const Header = React.memo(() => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const handleTest = async () => {
    const response = await API.get('/test/last');

  };

  return (
    <header className="App-header" id="header">
      <div id="header-main">
        <div className='row'>
          <div className='col' id='header-main-left'>



          </div>
          <div className='col' id='header-main-center'>
            <Link className='logo' to="/">
              <img src='/img/logo-header.png' alt='IF' />
            </Link>
          </div>

          <div className='col' id='header-main-right'>
            {/*<Header_User /> *]
            {/* <Header_Cart /> */}
          </div>
        </div>
      </div>
    </header >
  );
});

export default React.memo(Header);
