import React, { useEffect, useState, useRef } from 'react';
import { FiCamera } from 'react-icons/fi';
import { MdClose } from "react-icons/md";
import { LuSwitchCamera } from "react-icons/lu";
import './camera-capture.css';
import { swalAlert } from 'utils';
const CameraCapture = ({ onSave, iconSize = 30, disabled = false }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [currentCamera, setCurrentCamera] = useState('environment'); // 'environment' pour la caméra arrière, 'user' pour la caméra avant
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  useEffect(() => {
  }, []);
  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          width: { ideal: 3000 },
          height: { ideal: 2000 },
          facingMode: currentCamera
        }
      };
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setIsCameraOpen(true);
          }
        })
        .catch(err => {
          swalAlert("Caméra inaccessible. (" + err.message + " ) ");
        });
    }
  };
  useEffect(() => {
    if (message != '') {
      window.setTimeout(() => {
        setMessage('')
      }, 2000);
    }
  }, [message])
  useEffect(() => {
    if (isCameraOpen) {
      const video = videoRef.current;
      const tracks = video?.srcObject?.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
      startCamera();
    }
  }, [currentCamera]);
  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (video && canvas && isCameraOpen) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        onSave(blob);
        setMessage('Photo ajoutée aux pièces jointes');
      }, 'image/jpeg', 0.70);
    }
  };
  const closeCamera = () => {
    const video = videoRef.current;
    if (video) {
      const tracks = video.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      setIsCameraOpen(false);
    }
  };
  const toggleCamera = () => {
    setCurrentCamera(currentCamera === 'environment' ? 'user' : 'environment');
  };
  return (
    <>
      {!isCameraOpen ? (
        <button className='btn btn-outline-primary py-2' onClick={startCamera} disabled={disabled}>
          <FiCamera size={iconSize} />
        </button>
      ) : (
        null
      )}
      <div
        id='CameraCapture'
        className='text-center'
        style={{ display: isCameraOpen ? 'block' : 'none' }} >
        <div>
          <video ref={videoRef} autoPlay className="fullscreen-video" ></video>
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        </div>
        <div className='controls controls-top text-center '>
          <div className='row '>
            <div className='col-4'></div>
            <div className='col-4 text-center'>
            </div>
            <div className='col-4 pt-2 text-end '>
              <span className=' p-2  pointer'
                onClick={closeCamera} disabled={disabled}>
                <MdClose size={iconSize} color='white' />
              </span>
            </div>
          </div>
        </div>
        <div className='controls controls-bottom text-center '>
          {message !== '' &&
            <div className='message p-1 mb-2' >{message}</div>
          }
          <div className='row'>
            <div className='col-4  '>
            </div>
            <div className='col-4 text-center  '>
              <span className='p-2 pointer' onClick={takePhoto} disabled={disabled}>
                <FiCamera size={50} color='white' />
              </span>
            </div>
            <div className='col-4 text-center '>
              <span className='p-2  pointer' onClick={toggleCamera} disabled={disabled}>
                <LuSwitchCamera size={30} color='white' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CameraCapture;
