import { PiLeafFill, PiLeafThin } from 'react-icons/pi';

const LeafCheckbox = ({
  iconSize = 14,
  iconColor = 'rgba(1,1,1,1)',
  className = '',
  inputClassName = 'me-1',
  name = '',
  value = '',
  style = {},
  checked = false,
  icon = '',
  pinColor = '',
  type = 'checkbox',
  label = '',
  disabled = false,
  onChange }) => {
  const spanStyle = {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',

    ...style,
  };
  return (
    <span style={{ ...spanStyle, opacity: disabled ? '0.7' : '1' }} className={`leaf-checkbox ${className}`}>
      <input
        type={type}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        value={value}
        label=''
        style={{ display: 'none' }}
      />
      {icon == '' && pinColor == '' &&
        (<i className={`check ${inputClassName}`} style={{ color: iconColor }}>
          {checked && <PiLeafFill size={iconSize} />}
          {!checked && <PiLeafThin size={iconSize} />}
        </i>)
      }
      {icon != '' &&
        (<span>
          <img src={icon} height={30} />
        </span>)
      }
      {pinColor != '' &&
        (<span className='round-pin' style={{ backgroundColor: pinColor }}  >
        </span>)
      }{label}
    </span>
  );
};
export default LeafCheckbox; 