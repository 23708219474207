// DeviceContext.js
import React, { createContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

// Création du contexte
const DeviceContext = createContext();

// Composant fournisseur qui détermine le type de dispositif et fournit ces informations
const DeviceProvider = ({ children }) => {
  const deviceInfo = {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet, // Supposition : si ce n'est ni mobile ni tablette, alors c'est un desktop
  };

  return (
    <DeviceContext.Provider value={deviceInfo}>
      {children}
    </DeviceContext.Provider>
  );
};

// Exporte à la fois le Provider et le Contexte pour une utilisation ailleurs
export { DeviceProvider, DeviceContext };