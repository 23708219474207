import React, { useEffect, useState } from 'react';
import { useProjectMapContext } from '../../hooks/useProjectMapContext';
import useMapService from '../../hooks/useMapService';
import useItemsService from 'components/projects/hooks/useItemsService';
import './../../../../css/ui-toolbar.css';
import L from 'leaflet';
import ProjectIcons from 'components/projects/helpers/ProjectIcons';
const ProjectDrawingCrontrols = ({ className = '' }) => {
  const { userCan, selectedTool, setSelectedTool } = useProjectMapContext();
  const { isDrawing, finishDrawing } = useMapService();
  const tools = [
    { type: 'validate', label: 'Terminer le dessin' },
    { type: 'cancel', label: 'Annuler le dessin' },
  ];
  const handleClick = (tool_type) => {
    finishDrawing(tool_type);
  }
  return (
    (userCan('editItem') && isDrawing) &&
    <div className='ui-toolbar' id="ProjectDrawingCrontrols" >
      <ul className='list-unstyled  m-0 px-0 d-flex align-items-center me-2' >
        {tools.map(tool => (
          <li key={tool.type}
            className={`p-1 ${selectedTool === tool.type ? 'selected-tool' : ''} ${tool.disabled ? 'disabled' : ''}`}
            data-tool={tool.type}
            title={tool.label}
            onClick={() => !tool.disabled ? handleClick(tool.type) : {}}
          >
            <ProjectIcons type={tool.type} size={20} label={tool.label} />
          </li>
        ))}
      </ul>
    </div>
  )
};
export default ProjectDrawingCrontrols;
/*
      <ul className='list-unstyled  m-0 px-0 d-flex align-items-center me-2' >
        {tools.map(tool => (
          <li key={tool.type}
            className={`p-1 ${selectedTool === tool.type ? 'selected-tool' : ''} ${tool.disabled ? 'disabled' : ''}`}
            data-tool={tool.type}
            title={tool.label}
            onClick={() => !tool.disabled ? handleClick(tool.type) : {}}
          >
            <ProjectIcons type={tool.type} size={20} label={tool.label} />
          </li>
        ))}
      </ul>

*/