import React, { useEffect, useState, useContext } from 'react';
import { FaCog, FaMountain, FaMap } from 'react-icons/fa';
import { MdOutlineComputer } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { DeviceContext } from './../../../DeviceContext'
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import ProjectItemsToolbar from './topbar/ProjectItemsToolbar';
import ProjectDrawingCrontrols from './topbar/ProjectDrawindControl';

const ProjectTopbar = ({ options, doAction }) => {
  const { mapType,
    setMapTypeId, getProject,
    setShowProjectParams,
    showModalProjectParams,
    userCan,
    uiMode,
    setUiMode,
    selectedTool,
    setSelectedTool,
  } = useProjectMapContext();
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const dispatch = useDispatch();
  const project = useSelector(state => state.project);
  const config = useSelector(state => state.config);
  return (
    project?.projId && (
      <div id="ProjectTopbar" className='d-flex justify-content-between align-items-strech'>
        <div className='d-flex justify-content-start'>
          <span className="cell">
            <h1 className='d-flex align-items-center justify-content-center'>
              {(userCan('editParams')) &&
                <span className='pointer d-block me-2' style={{ marginTop: '-3px' }} onClick={() => setShowProjectParams(true)}>
                  <FaCog size={17} />
                </span>
              }
              <span className='pointer' onClick={e => getProject(project.projId)}>{project?.projName}</span>
            </h1>
          </span>
          {uiMode == 'desktop' &&
            <>
              <span className="cell">
                <div className='input-group'>
                  <select className='form-control form-control-sm'
                    style={{ maxWidth: isMobile ? '60px' : '180px' }}
                    name='mapTypeId'
                    value={mapType?.id}
                    onChange={e => setMapTypeId(e.target.value)}>
                    {config.map_types?.map((type, index) => (
                      <option key={index} value={type.id}>{type.name}</option>
                    ))}
                  </select>
                </div>
              </span>
              <span className="cell">
                <ProjectItemsToolbar />
              </span>
              <span className="cell">
                <ProjectDrawingCrontrols />
              </span>

            </>
          }
        </div>
        <div className='d-flex justify-content-end'>
          {(isMobile || isTablet || true) &&
            <span className="cell pointer" onClick={e => setUiMode(uiMode == 'desktop' ? 'field' : 'desktop')}>
              {uiMode == 'desktop' ? <FaMountain size={20} /> : <MdOutlineComputer size={20} />}
            </span>
          }
        </div>
      </div>
    ));
};
export default ProjectTopbar;
