import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import projectReducer from './projectSlice';
import configReducer from './configSlice';
import utilsReducer from './utilsSlice';
import userPreferencesSlice from './userPreferencesSlice';
const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    config: configReducer,
    utils: utilsReducer,
    userPreferences: userPreferencesSlice,
  },
});
export default store;
