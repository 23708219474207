import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputAutocomplete from '../../helpers/InputAutocomplete/InputAutocomplete'
const TreeSpeciesSelector = ({ essence_fr, essence_lt, onUpdate }) => {
  const treesTaxonomy = useSelector(state => state.config.treesTaxonomy);
  const [essenceFr, setEssenceFr] = useState(essence_fr);
  const [essenceLt, setEssenceLt] = useState(essence_lt);
  useEffect(() => {
    setEssenceFr(essence_fr)
    setEssenceLt(essence_lt)
  }, [essence_fr, essence_lt])
  const sortTreesTaxonomy = (key) => {
    const sortedData = [...treesTaxonomy].sort((a, b) =>
      a[key].localeCompare(b[key])
    );
    return sortedData;
  };
  const handleChange = () => {
    onUpdate({
      essence_fr: essenceFr,
      essence_lt: essenceLt,
    });
  }
  const formatSuggestion = (name, suggestion) => {
    let suggestionText = '';
    switch (name) {
      case 'essence_fr':
        suggestionText = `${suggestion.essence_fr} (${suggestion.essence_lt})`; // Format personnalisé des suggestions
        break;
      case 'essence_lt':
        suggestionText = `${suggestion.essence_lt} (${suggestion.essence_fr})`; // Format personnalisé des suggestions
        break;
    }
    return suggestionText;
  };
  const handleOnChange = (e) => {
    switch (e.target.name) {
      case 'essence_fr':
        onUpdate({ essence_fr: e.target.value });
        break;
      case 'essence_lt':
        onUpdate({ essence_lt: e.target.value });
        break;
    }
  }
  const handleSuggestionSelect = (name, suggestion) => {
    onUpdate({
      essence_fr: suggestion.essence_fr,
      essence_lt: suggestion.essence_lt,
    });
    return;
  }
  return <div className="tree-species-selector">
    <div className='form-group'>
      <label className='form-group-label'>Essence :</label>
      <InputAutocomplete
        suggestionsSrc={sortTreesTaxonomy('essence_fr')}
        name='essence_fr'
        value={essenceFr}
        inputClassName='form-control-sm'
        displayKey="essence_fr"
        onChange={handleOnChange}
        formatSuggestion={formatSuggestion}
        onSuggestionSelect={handleSuggestionSelect} />
    </div>
    <div className='form-group'>
      <label className='form-group-label'>Nom latin :</label>
      <InputAutocomplete
        suggestionsSrc={sortTreesTaxonomy('essence_lt')}
        name='essence_lt'
        value={essenceLt}
        inputClassName='form-control-sm'
        displayKey="essence_lt"
        onChange={handleOnChange}
        formatSuggestion={formatSuggestion}
        onSuggestionSelect={handleSuggestionSelect} />
    </div>
  </div>
}
export default TreeSpeciesSelector;