import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { debug, swalAlert } from './../../../utils';
import { cloneDeep, join } from 'lodash';
import API from './../../../api';
import { fetchUserDetails } from './../../../redux/userSlice';
import CustomAlert from './../../helpers/CustomAlert/CustomAlert';
import ResponsiveNavTabs from '../../helpers/ResponsiveNavTabs/ResponsiveNavTabs';
import { HtmlElement } from './../../helpers/HtmlElements/HtmlElements';
import ProjectParams from './inc/ProjetParams';
import ProjectMedias from './inc/ProjectMedias';
import ProjectMeta from './inc/ProjectMeta';
import { setProject } from '../../../redux/projectSlice';
const ModalProjectParams = ({ show, handleClose = null }) => {
  const user = useSelector((state) => state.user);
  const {
    userCan,
  } = useProjectMapContext();
  const project = useSelector(state => state.project);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [dirty, setIsDirty] = useState(false);
  const [projectCopy, setProjectCopy] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setProjectCopy(cloneDeep(project))
  }, [project]);
  useEffect(() => {
  }, [projectCopy]);
  const handleChange = (data) => {

    if (data && data.target) {
      setProjectCopy({ ...projectCopy, [data.target.name]: data.target.value });
    }
    else if (typeof data === 'object') {
      setProjectCopy({ ...projectCopy, ...data });

    }
    setIsDirty(true);
  };
  const submit = async (value) => {
    try {
      const response = await API.put(`/project/${project.projId}`, projectCopy);
      dispatch(setProject(response.data));
    } catch (error) {
      swalAlert(error.response.data.error.message)
    }
    setIsDirty(false);
  }
  return (
    (projectCopy?.projId && userCan('editParams')) &&
    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Paramètres du projet "{project?.projName}"
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ResponsiveNavTabs tabNames={['Paramètres', 'Pièces jointes', 'Champs']} activeTab={activeTab} setActiveTab={setActiveTab} >
          <HtmlElement.submit text="Sauvegarder" onClick={submit} className={`${dirty ? 'btn-danger' : 'btn-outline-primary'}`} />
        </ResponsiveNavTabs>
        <div className={`tab-content flex-grow-1`} >
          <div className={`tab-pane fade show ${activeTab == 0 ? 'active' : ''}`} >
            <ProjectParams project={projectCopy} onChange={handleChange} />
          </div>
          <div className={`tab-pane fade show ${activeTab == 1 ? 'active' : ''}`} >
            <ProjectMedias project={projectCopy} onChange={handleChange} />
          </div>
          <div className={`tab-pane fade show ${activeTab === 2 ? 'active' : ''}`} >
            <ProjectMeta meta={projectCopy.projMeta} onChange={(updatedMeta) => setProjectCopy(prevProject => ({ ...prevProject, projMeta: updatedMeta }))} />
          </div>
          <div className={`tab-pane fade show ${activeTab === 2 ? 'active' : ''}`} >
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalProjectParams;
