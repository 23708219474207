import React, { useRef, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaPencilAlt } from "react-icons/fa";
import { HtmlElement } from './../HtmlElements/HtmlElements';
import API from 'api';
const HandDrawnEditor = ({ iconSize = 30, imageSrc = null, onSave, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#000000'); // Couleur par défaut
  const canvasRef = useRef(null);
  const currentColor = useRef('#000000');
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const colors = ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF', '#FFFFFF']; // Exemple de couleurs
  const handleSave = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const imageDataUrl = canvas.toDataURL("image/png");
      fetch(imageDataUrl)
        .then(res => res.blob())
        .then(blob => {
          if (onSave && typeof onSave === 'function') {
            onSave(blob);
          }
          handleCloseModal();
        })
        .catch(error => console.error('Erreur lors de la conversion de l\'image en Blob:', error));
    }
  };
  useEffect(() => {
    if (showModal) {
      const canvas = canvasRef.current;
      const modalBody = canvas.closest('.modal-body');
      if (modalBody) {
        const style = getComputedStyle(modalBody);
        const width = parseInt(style.width, 10);
        canvas.width = width - 30; // 30px de marge, ajuste selon tes besoins
        canvas.height = Math.max(400, window.innerHeight - 300); // Hauteur maximale avec ajustement dynamique
      }
    }
  }, [showModal]);
  useEffect(() => {
    currentColor.current = selectedColor; // Mettre à jour la ref lorsque selectedColor change
  }, [selectedColor]);
  const initCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (imageSrc) {
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
      image.src = imageSrc;
    }
    let drawing = false;
    const getTouchPos = (canvasDom, touchEvent) => {
      const rect = canvasDom.getBoundingClientRect();
      return {
        x: (touchEvent.touches[0].clientX - rect.left),
        y: (touchEvent.touches[0].clientY - rect.top)
      };
    };
    const draw = (x, y) => {
      if (!drawing) return;
      ctx.lineWidth = 5;
      ctx.lineCap = 'round';
      ctx.strokeStyle = currentColor.current;
      ctx.lineTo(x, y);
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, y);
    };
    const mouseEventToCoord = (e) => {
      const rect = canvas.getBoundingClientRect();
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
    };
    const startDrawing = (e) => {
      drawing = true;
      const { x, y } = e.touches ? getTouchPos(canvas, e) : mouseEventToCoord(e);
      ctx.moveTo(x, y);
      draw(x, y);
    };
    const keepDrawing = (e) => {
      if (!drawing) return;
      const { x, y } = e.touches ? getTouchPos(canvas, e) : mouseEventToCoord(e);
      draw(x, y);
    };
    const stopDrawing = () => {
      drawing = false;
      ctx.beginPath();
    };
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', keepDrawing);
    window.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('touchmove', keepDrawing);
    canvas.addEventListener('touchend', stopDrawing);
    canvas.addEventListener('touchstart', (e) => e.preventDefault(), { passive: false });
    canvas.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
  };
  return (
    <>
      <Button variant="outline-primary" size="md" className="py-2" onClick={handleOpenModal} disabled={disabled}>
        <FaPencilAlt size={iconSize} />
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} onEntered={initCanvas}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <canvas ref={canvasRef} width="10" height="400" style={{ border: '1px solid #000' }}></canvas>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
              {colors.map(color => (
                <div key={color} onClick={() => setSelectedColor(color)} style={{ backgroundColor: color, width: '25px', height: '25px', margin: '0 5px', cursor: 'pointer' }}></div>
              ))}
            </div>
          </div>
          <div className='mb-3'>
            <HtmlElement.submit onClick={handleSave} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HandDrawnEditor
