// useItemsService.js
import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet'; // Assure-toi d'importer la bibliothèque Leaflet
import { useNavigate, useParams } from 'react-router-dom';
import { cloneDeep, rest, transform } from 'lodash';
import { useDispatch, useSelector } from 'react-redux'; // Importe les hooks Redux
import { updateProject } from './../../../redux/projectSlice'; // Importe les actions Redux
import { setPreferences } from './../../../redux/userPreferencesSlice';
import { useProjectMapContext } from './useProjectMapContext';
import API from './../../../api';
import { swalAlert, swalConfirm } from './../../../utils';
const useItemsService = () => {
  const dispatch = useDispatch(); // Initialise le dispatch Redux
  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);
  const {
    itemContextMenuOptions, setItemContextMenuOptions,
    setMapContextMenuOptions,
    selectedItem, setItemSelected,
    userCan,
    activeLayer, setActiveLayer
  } = useProjectMapContext();
  const [unlockTimeouts, setUnlockTimeouts] = useState([]);
  useEffect(() => {
  }, [unlockTimeouts])
  const setItemUnlockTimeout = (itId, time) => {
    const unlockTimeoutsCopy = [...unlockTimeouts];
    const index = unlockTimeoutsCopy.findIndex(ti => ti.itId === itId);
    if (index === -1) {
      unlockTimeoutsCopy.push({ itId: itId, time: time })
    } else {
      unlockTimeoutsCopy[index] = { itId: itId, time: time };
    }
    setUnlockTimeouts([...unlockTimeoutsCopy]);
  }
  const [my_item, set_my_item] = useState({});
  useEffect(() => {
  }, [project?.layers])
  const handle_item_change = (item, obj) => {
    if (obj.target) {
      const { name, value } = obj.target;
      const parts = name.split('.');
      let newObj
      if (parts.length == 1) {
        newObj = { ...item, [parts[0]]: value };
      } else {
        newObj = { ...item, [parts[0]]: { ...selectedItem[[parts[0]]], [parts[1]]: value } }
      };
      return newObj;
    } else {
      return ({ ...item, ...obj });
    }
  }
  /**
   * 
   * create_item
   */
  const create_item = async data => {
    if (data.itType && data.itType != '') {
      try {
        data.itId = '';
        data.itProjId = project.projId;
        if (!data.itName) {
          data.itName = 'Sans nom';
        }
        if (data.itType != 'layer' && !data.itparentId) {
          if (userPreferences.projects?.[project.projId]?.activeLayerId) {
            data.itParentId = userPreferences.projects?.[project.projId]?.activeLayerId;
          } else {
            data.itParentId = project.layers[0].itId;
          }
        }
        data.meta = {};
        data.medias = [];
        setItemSelected(data);
        setItemContextMenuOptions({ show: false });
        setMapContextMenuOptions({ show: false });
      } catch (error) {
      }
    }
  }
  const save_item = async (data) => {
    try {
      data.itOrder = 0;
      const response = await API.post('/item', data);
      const item = response.data;
      item.unlocked = false;
      const payload = { items: [item, ...project.items] };
      if (item.children && item.children.length > 0) {
        payload.items = [...item.children, ...payload.items];
      }
      if (item.itType == 'layer') {
        dispatch(setPreferences('projects', item.itProjId, {
          activeLayerId: item.itId,
          visibleLayersId: [...userPreferences.projects[project.projId].visibleLayersId, item.itId]
        }))
        let layers = [{ ...item }, ...project.layers];
        layers = sort_layers([...layers])
        payload.layers = [...layers];
      }
      dispatch(updateProject(payload))
      return item;
    } catch (error) {

      // swalAlert(error.response ? error.response.data.error.message : error.message);
    }
  }
  const update_item = async (item) => {
    try {
      const response = await API.put('/item/' + item.itId, item);
      const items = [...project.items];
      const itemIndex = items.findIndex(it => it.itId === item.itId);
      items[itemIndex] = { ...response.data, unlodked: true, relockAt: new Date().getTime() + 3000 };
      const payload = { items: items.filter(it => it.itStatus !== 'deleted') }; // Filtrage au lieu de delete
      if (response.data.itType === 'layer') {
        let layers = [...project.layers];
        const layerIndex = layers.findIndex(it => it.itId === item.itId);
        if (response.data.itStatus !== 'deleted') {
          layers[layerIndex] = { ...layers[layerIndex], ...response.data };
        } else {
          layers = layers.filter((_, index) => index !== layerIndex);
          dispatch(setPreferences('projects', item.itProjId, { activeLayerId: layers[0].itId }))
        }
        payload.layers = layers;
      }
      dispatch(updateProject(payload));
      return response.data;
    } catch (error) {
      swalAlert(error.response.data.error.message);
    }
  }
  const update_items = async (payload) => {
    try {
      const response = await API.put('/items', payload);
      const items = [...project.items];
      const layers = [...project.layers];
      let layersFound = false;
      response.data.forEach(item => {
        const index = items.findIndex(it => it.itId === item.itId);
        items[index] = { ...items[index], ...item };
        if (item.itType === 'layer') {
          layersFound = true;
          const index = layers.findIndex(it => it.itId === item.itId);
          layers[index] = { ...layers[index], ...item };
        }
      });
      dispatch(updateProject({ items: [...items] }))
      if (layersFound) {
        dispatch(updateProject({ layers: [...sort_layers([...layers], false)] }));
      }
    } catch (error) {
      error.response ? swalAlert(error.response.data.error.message) : console.log(error);
    }
  }
  const handleItemMenuClick = async (action) => {
    const item = itemContextMenuOptions.item;
    switch (action) {
      case 'close':
        setItemContextMenuOptions({ show: false });
        break;
      case 'edit':
        setItemSelected(cloneDeep(item));
        setItemContextMenuOptions({ show: false });
        break;
      case 'unlock':
        if (userCan('editItem')) {
          const items = [...project.items];
          const index = items.findIndex(it => it.itId === item.itId);
          items[index] = { ...items[index], unlocked: !item.unlocked };
          setItemUnlockTimeout(item.itId, items[index].unlocked ? new Date().getTime() + 5000 : 0);
          dispatch(updateProject({ items: [...items] }))
          setItemContextMenuOptions({ show: false });
        }
        break;
      case 'delete':
        setItemContextMenuOptions({ show: false })
        if (userCan('editItem')) {
          const options = {
            message: `Confirmez-vous la suppression de l' élément "${item.itRef} " ?`
          }
          const response = await swalConfirm(options);
          if (response) {
            update_item({ ...item, itStatus: 'deleted' });
          }
        }
        break;
      default:
      // alert(action);
    }
  };
  const sort_layers = (layers, update_project = false) => {
    layers.sort(function (it1, it2) {
      if (it1.itOrder > it2.itOrder) return 1;
      if (it1.itOrder < it2.itOrder) return -1;
      if (it1.itId < it2.itId) return 1;
      if (it1.itId > it2.itId) return -1;
    });
    if (update_project) {
      dispatch(updateProject({ layers: layers }));
    }
    layers.forEach(l => {
    })
    return layers;
  }
  return {
    create_item,
    save_item,
    update_item,
    update_items,
    handleItemMenuClick,
    handle_item_change,
    my_item,
    setItemUnlockTimeout,
  };
};
export default useItemsService;
