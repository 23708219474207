import { tooltip } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { FaCog, FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from '../../../../redux/projectSlice';
import { debug } from 'utils';
const MetaTogglerPanel = ({ className }) => {
  const dispatch = useDispatch();
  const project = useSelector(state => state.project);
  const config = useSelector(state => state.config);
  const toggleMetaVisibility = (id) => {
    const metaVisibilityCopy = cloneDeep(project.metaVisibility);
    const index = metaVisibilityCopy.findIndex(it => it.id === id);
    metaVisibilityCopy[index] = { ...metaVisibilityCopy[index], visible: !metaVisibilityCopy[index].visible };
    dispatch(updateProject({ metaVisibility: metaVisibilityCopy }));
  };
  return (
    <div id="MetaTogglerPanel" className={className}>
      <dl className='meta'>
        <dt className='pointer'>
          Affichage
        </dt>
        {project.metaVisibility.map((m, idx) => (
          <dd
            key={idx}
            className={`py-1 d-flex layer ${m.visible ? 'visible' : 'hidden'}`}>
            <span onClick={e => toggleMetaVisibility(m.id)}
              className='pointer me-1'><FaEye size={12} color={m.stroke} />
            </span>
            <div>{m.name}</div>
          </dd>
        ))}
      </dl>
      {debug(project.metaVisibility, 0)}
    </div>
  );
};
export default MetaTogglerPanel;
