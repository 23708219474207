import axios from 'axios';
const api_url = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: api_url,
  timeout: 30000,
});

API.getUrl = () => {
  return api_url;
}

API.getRequestHeaders = () => {
  const headers = {};
  // Tente de récupérer le token d'authentification stocké
  const token = localStorage.getItem('userToken');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

API.interceptors.request.use(
  (config) => {
    // Injecte le token d'authentification dans les en-têtes de la requête
    config.headers = { ...config.headers, ...API.getRequestHeaders() };
    return config;
  },
  (error) => {
    // Gère les erreurs de configuration de la requête
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Gérer l'erreur ici
    console.error('Une erreur s\'est produite:', error);
    // Retourner une promesse rejetée pour propager l'erreur
    return Promise.reject(error);
  }
);

export default API;
