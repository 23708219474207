import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useProjectMapContext } from '../../../hooks/useProjectMapContext';
import useItemsService from '../../../hooks/useItemsService';
import { HtmlElement } from '../../../../helpers/HtmlElements/HtmlElements';
import useFormHandler from '../../../hooks/useFormHandler'; // Assure-toi que le chemin d'importation est correct

import { debug } from '../../../../../utils';

const LineForm = ({ item = {} }) => {
  const project = useSelector((state) => state.project);
  const { setItemSelected, userCan } = useProjectMapContext();
  const { save_item, update_item } = useItemsService();
  const [itemCopy, handleChange] = useFormHandler(item); // Utilisation du hook useFormHandler
  const [layersListOptions, setLayersListOptions] = useState([]);
  useEffect(() => {
    if (itemCopy.itId !== item.itId) {
      handleChange(item);
    }
  }, [item, item.itId, handleChange]);
  /*
  useEffect(() => {
    setLayersListOptions(project.layers.map(layer => ({
      value: layer.itId,
      label: layer.itName
    })));
  }, []):
  */
  const submit = async () => {
    let response = null;
    if (!itemCopy.itId || itemCopy.itId === '') {
      response = await save_item(itemCopy);
    } else {
      response = await update_item(itemCopy);
    }
    setItemSelected(null);
  };
  return (
    <div id="MarkerForm" className='pb-5'>
      <div className='mb-5'>
        <HtmlElement.formGroup
          value={itemCopy.itName || ''}
          name='itName'
          label='Nom :'
          onChange={handleChange}
          required={true}
          className='mb-3'
        />
        <HtmlElement.formGroup
          type='textarea'
          value={(itemCopy.meta && itemCopy.meta.description) || ''}
          name='meta.description'
          label='Description :'
          onChange={handleChange}
          required={true}
          className='mb-3'
          rows={6}
        />
        <HtmlElement.formGroup
          type='colorSelect'
          name="itFeatures.color"
          allowEmpty={false}
          value={(itemCopy.itFeatures && itemCopy.itFeatures.color) || ''}
          onChange={handleChange}
          className='mb-3'
        />
        <HtmlElement.formGroup
          type='select'
          value={itemCopy.itParentId}
          name='itParentId'
          label='Calque :'
          onChange={handleChange}
          required={true}
          options={[...layersListOptions]}
          className='mb-0'
          rows={6}
        />
      </div>
      {userCan('editItem') &&
        <HtmlElement.submit text="Sauvegarder" onClick={submit} />
      }
      {debug(itemCopy, 0)}
    </div>
  );
};

export default LineForm;
