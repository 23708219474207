import React, { useEffect, useState } from 'react';

import { FiPlusCircle } from 'react-icons/fi';
import useItemsService from '../../hooks/useItemsService';
import { useProjectMapContext } from '../../hooks/useProjectMapContext';
import { cloneDeep, unset } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setPreferences } from '../../../../redux/userPreferencesSlice';
import { swalInput, swalConfirm } from '../../../../utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import './project-layers-panel.css';
const ProjectLayersPanel = ({ className = '' }) => {
  const {
    userCan,
    setItemSelected,
  } = useProjectMapContext();
  const { create_item, update_item, update_items, set_active_layer } = useItemsService();
  const dispatch = useDispatch();
  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);
  const config = useSelector(state => state.config);
  const [activeLayerId, setActiveLayerId] = useState('');
  const toggleLayerVisibility = (itId) => {
    const currentVisibleLayersId = userPreferences.projects?.[project.projId]?.visibleLayersId || [];
    const updatedVisibleLayersId = currentVisibleLayersId.includes(itId)
      ? currentVisibleLayersId.filter(id => id !== itId) // Retirer
      : [...currentVisibleLayersId, itId]; // Ajouter
    dispatch(setPreferences('projects', project.projId, {
      ...userPreferences.projects?.[project.projId],
      visibleLayersId: updatedVisibleLayersId
    }));
  }
  const setActiveLayer = (layer) => {
    dispatch(setPreferences('projects', project.projId, { activeLayerId: layer.itId }));
  }
  useEffect(() => {
    if (activeLayerId && project.layers.length > 0) {
      if (project.layers.findIndex(l => l.itId === activeLayerId) == -1) {
        dispatch(setPreferences('projects', project.projId, { activeLayerId: project.layers[0].itId }));
      }
    }
  }, [activeLayerId])
  useEffect(() => {
    if (userPreferences.projects?.[project.projId]?.activeLayerId) {
      setActiveLayerId(userPreferences.projects?.[project.projId]?.activeLayerId)
    }
    if (!userPreferences.projects?.[project.projId]?.visibleLayersId) {
      const allLayersId = project.layers.map(layer => layer.itId);
      dispatch(setPreferences('projects', project.projId, {
        ...userPreferences.projects?.[project.projId],
        visibleLayersId: allLayersId
      }));
    }
  }, [userPreferences]);
  const createLayer = async () => {
    create_item({ itType: 'layer', itFeatures: { color: '', opacity: "0.2" } });
  }
  const editLayer = async (item) => {
    setItemSelected(item)
  }
  const deleteLayer = async (item) => {
    if (project.layers.length > 1) {
      const response = await swalConfirm({ message: 'Supprimer ce calque et toutes ses dépendances ?' })
      if (response) {
        update_item({ ...item, itStatus: 'deleted' });
      }
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const layers = cloneDeep(project.layers);
    const [removed] = layers.splice(result.source.index, 1);
    layers.splice(result.destination.index, 0, removed);
    const payload = {}
    layers.forEach((layer, index) => {
      payload[layer.itId] = { itOrder: index };
      layer.itOrder = index;
    });
    update_items(payload);
  };
  return (
    <div id="ProjectLayersPanel" className={className}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="layers">
          {(provided) => (
            <dl {...provided.droppableProps} ref={provided.innerRef} className='layers'>
              <dt className='pointer'>
                Calques
                {userCan('editLayers') &&
                  <div>
                    <span onClick={createLayer}><FiPlusCircle size={15} /></span>
                  </div>
                }
              </dt>
              {project.layers.map((l, idx) => (
                <Draggable key={l.itId} draggableId={l.itId} index={idx} isDragDisabled={!userCan('editLayers')}>
                  {(provided, snapshot) => (
                    <dd
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}

                      className={`py-1 d-flex layer ${userPreferences.projects?.[project.projId]?.visibleLayersId?.includes(l.itId) ? 'visible' : 'hidden'} ${activeLayerId == l.itId ? 'active' : ''}`}>

                      <span onClick={e => toggleLayerVisibility(l.itId)}
                        className='pointer me-1'><FaEye size={12} color={l.itFeatures.color} />
                      </span>
                      <span
                        className='flex-grow-1 overflow-hidden'
                        onClick={e => setActiveLayer(l)}>
                        {l.itName}
                      </span>
                      {userCan('editLayers') &&

                        <div className='buttons'>
                          <span className='pointer ms-2' onClick={e => editLayer(l)}>
                            <FaEdit size={12} />
                          </span>
                          <span className={`pointer ms-2 ${project.layers.length == 1 ? 'disabled' : ''}`} onClick={e => deleteLayer(l)}>
                            <FaTrash size={11} />
                          </span>
                        </div>
                      }
                    </dd>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </dl>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default ProjectLayersPanel;
