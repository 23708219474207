import Picture from 'components/helpers/Picture/Picture';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debug, formatDate, swalAlert, swalInput, file, download } from 'utils';
import API from 'api';
import MediaViewer from './MediaViewer';

import Uploader from 'components/helpers/Uploader/Uploader';
/** ICONES */
import { BsPencil, BsFiletypePdf } from "react-icons/bs";
import { TfiNewWindow } from "react-icons/tfi";
import { FiPlay, FiSquare } from "react-icons/fi";
import { BiTrashAlt } from "react-icons/bi";
import { PiFile, PiFileAudioLight } from "react-icons/pi";
import { GrDownload } from "react-icons/gr";

const MediasList = ({ medias, onChange, parentId = '' }) => {
  const [mediaViewerIndex, setMediaViewerIndex] = useState(-1);
  const user = useSelector((state) => state.user);
  const config = useSelector((state) => state.config);
  const audio = useRef(null);
  const [currentMediaId, setCurrentMediaId] = useState(null);
  useEffect(() => {
    audio.current = new Audio()
    audio.current.onended = () => {

      setCurrentMediaId(null);
    }
  }, []);
  useEffect(() => {

  }, [audio]);
  const handleNameChange = async (index) => {
    const extension = medias[index].medName.split('.').pop(); // Extraire l'extension
    const nameWithoutExtension = medias[index].medName.replace(new RegExp(`.${extension}$`), ''); // Supprimer l'extension du nom pour l'édition
    const newName = await swalInput({
      title: 'Modifier le nom du fichier',
      value: nameWithoutExtension,
      placeholder: 'Entrez le nouveau nom du fichier'
    });
    if (newName) {
      const mediasUpdated = [...medias];
      try {
        const response = await API.put(`/media/${mediasUpdated[index].medId}`, { medName: `${newName}.${extension}` });
        mediasUpdated[index] = response.data;

        onChange([...mediasUpdated])
      } catch (e) {
        swalAlert(e);
      }
    }
  };
  const onUploadStart = (data) => {

  }
  const onUploadSuccess = async (data) => {

    const mediasUpdated = [...medias];
    const media = ({
      'medName': data.name,
      'medPath': data.path,
      'medMimeType': data.type,
      'medParentId': parentId,
    });
    try {

      const response = await API.post('/media', media);

      mediasUpdated.push(response.data);
      onChange([...mediasUpdated])
    } catch (error) {
      swalAlert(error);
    }
  }
  const deleteMedia = (index) => {
    const mediasUpdated = [...medias];
    const media = mediasUpdated[index]
    if (media.medId === currentMediaId) {
      audio.current.pause();
      setCurrentMediaId(null);
    }
    media.medStatus = 'deleted';
    mediasUpdated[index] = media;
    onChange([...mediasUpdated])
  }
  const onUploadFailure = (data) => {

  }
  const playAudio = async (index) => {
    const media = medias[index];
    if (currentMediaId === media.medId) {
      setCurrentMediaId(null);
      audio.current.pause();
    } else {
      try {
        audio.current.pause();
        const response = await file(media.medPath);
        audio.current.src = response
        setCurrentMediaId(media.medId);
        audio.current.play();
      } catch (error) {
        swalAlert(error);
      }
    }
  }
  return (
    <>
      <div>
        <table className='table table-sm'>
          <tbody>
            {medias && medias.map((media, index) => (
              media.medStatus != 'deleted' && <tr key={index} className="">
                <td style={{ width: '50px' }}>
                  {media.medMimeType === 'text/plain' &&
                    <PiFile size="40" />
                  }
                  {media.medMimeType.startsWith('image/') &&
                    <span className='border d-inline-block'>
                      <Picture src={media.medId} size='maxw=40&maxh=40' />
                    </span>
                  }

                  {media.medMimeType == 'application/pdf' &&
                    <span className='border d-inline-block'>
                      <BsFiletypePdf size="38" />
                    </span>
                  }





                  {media.medMimeType.startsWith('audio/') &&
                    <>
                      <span className='border d-inline-block'>
                        <PiFileAudioLight size="40" />
                      </span>
                      <span className='ms-3 pointer' onClick={e => playAudio(index)}>
                        {(media.medId == currentMediaId) ? <FiSquare size={18} /> : <FiPlay size={18} />}
                      </span>
                    </>
                  }
                </td>
                <td className='align-middle'>
                  <span className='d-block w-100 overflow-hidden'>
                    <strong>{media.medName}</strong> <span onClick={e => handleNameChange(index)}><BsPencil size={12} /></span><br />
                    {media.medMimeType} &bull; {formatDate(media.medCreatedAt, 'd/m/Y H:i')}
                  </span>
                </td>
                <td className='align-middle text-end' style={{ whiteSpace: 'nowrap' }}>
                  <span className='ms-3 pointer' onClick={e => setMediaViewerIndex(index)}>
                    <TfiNewWindow size={18} />
                  </span>
                  <span className='ms-3 pointer' onClick={e => download('/utils/file' + media.medPath)}>
                    <GrDownload size={18} />
                  </span>
                  <span className='ms-3 pointer' onClick={e => deleteMedia(index)}>
                    <BiTrashAlt size={18} />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <Uploader
            profile='projects_001'
            size='large'
            onUploadStart={onUploadStart}
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
          />
        </div>
      </div >
      {mediaViewerIndex > -1 && <MediaViewer initialIndex={mediaViewerIndex} onClose={e => setMediaViewerIndex(-1)} medias={medias} />
      }
    </>
  )
}
export default MediasList;
