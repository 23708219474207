import React from 'react';
import { ProjectMapProvider } from './hooks/useProjectMapContext';
import ProjectMapInner from './ProjectMapInner'; // Assure-toi d'importer le sous-composant
import L from 'leaflet';
import 'leaflet-editable'
const ProjectMap = () => {
  return (
    <div id="ProjectMap">
      <ProjectMapProvider>
        <ProjectMapInner />
      </ProjectMapProvider>
    </div>
  );
};

export default ProjectMap;