import React, { useState } from 'react';
import { useProjectMapContext } from '../../hooks/useProjectMapContext';
import L from 'leaflet'; // Assure-toi que Leaflet est importé
import { useSelector } from 'react-redux';
import { RiSearchLine } from "react-icons/ri";
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
const ProjectItemSearch = ({ className = '' }) => {
  const { mapRef } = useProjectMapContext();
  const project = useSelector(state => state.project);
  const [searchString, setSearchString] = useState(""); // Initialisé à une chaîne vide
  const handleClick = () => {
    const normalizedValue = searchString.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (normalizedValue) {
      const index = project.items.findIndex((item) => {
        const normalizedItemValue = item.itRef?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return (item.itType === 'tree' || item.itType === 'marker') && normalizedItemValue === normalizedValue;
      });
      if (index > -1) {
        const item = project.items[index];
        if (mapRef && mapRef.current && item.itGeometry && item.itGeometry.length === 2) {
          mapRef.current.flyTo(item.itGeometry, 22);
          const circle = L.circle(item.itGeometry, {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 2 // Ajuste le rayon selon l'échelle de ta carte
          }).addTo(mapRef.current);
          // Supprime le cercle après 3 secondes
          setTimeout(() => {
            mapRef.current.removeLayer(circle);
          }, 1500);

        }
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };
  return (
    <div className={className}>
      <div className='input-group input-group-sm'>
        <input
          className='form-control form-control-sm'
          type="text"
          value={searchString}
          name='searchString'
          onChange={e => setSearchString(e.target.value)}
          onKeyPress={handleKeyPress} // L'écouteur d'événement est ajouté ici
          placeholder='référence'
        />
        <button className='btn btn-light' onClick={handleClick}><RiSearchLine /></button>
      </div>
    </div>
  );
};

export default ProjectItemSearch;
