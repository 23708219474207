// useMapService.js
import { useState, useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-editable';
import { useDispatch, useSelector } from 'react-redux';
import { useProjectMapContext } from './useProjectMapContext';
import useItemService from './useItemsService';
const useMapService = () => {
  const project = useSelector(state => state.project);
  const userPreferences = useSelector(state => state.userPreferences);
  const thisSelectedTool = useRef();
  const thisActiveLayerId = useRef();
  const [isDrawing, setIsDrawing] = useState(false);
  const {
    userCan,
    mapRef,
    setMapContextMenuOptions,
    setItemContextMenuOptions,
    selectedTool,
    activeLayer
  } = useProjectMapContext();
  useEffect(() => {
    if (userPreferences.projects?.[project.projId]?.activeLayerId) {
      thisActiveLayerId.current = userPreferences.projects[project.projId].activeLayerId;
    }
  }, [userPreferences])
  const currentDrawingLayer = useRef(null)
  const setCurrentDrawingLayer = (layer) => {
    currentDrawingLayer.current = layer;
  }
  useEffect(() => {
    if (currentDrawingLayer.current == null) {
      if (mapRef.current?.editTools?.drawing()) {
        mapRef.current.editTools?.stopDrawing();
      }
      try {
        mapRef.current.removeLayer(currentDrawingLayer.current);
      } catch (e) {
      }
    }
  }, [currentDrawingLayer.current]);
  const eventCatched = useRef(false);
  /**
  * 
  * editable:drawing:commit
  */
  mapRef.current?.on('editable:drawing:commit', event => {
    L.DomEvent.stopPropagation(event);
    mapRef.current.editTools?.stopDrawing();
    if (currentDrawingLayer.current?.options) {
      eventCatched.current = true;
      const item = { ...currentDrawingLayer.current.options.item }
      if (item.itType == 'circle') {
        item.itGeometry = currentDrawingLayer.current.getLatLng();
        item.itFeatures = { radius: currentDrawingLayer.current.getRadius() }
      } else {
        item.itGeometry = currentDrawingLayer.current.getLatLngs();
      }
      if (currentDrawingLayer.current.options.item.itId == '') {
        create_item({
          itName: '',
          itParentId: thisActiveLayerId.current,
          itStatus: 'active',
          ...item
        });
        mapRef.current.removeLayer(currentDrawingLayer.current);
        currentDrawingLayer.current = null;
      } else {
      }
    }
  });
  /**
   * editable:enable'
   */
  mapRef.current?.on('editable:enable', event => {
    L.DomEvent.stopPropagation(event);
    currentDrawingLayer.current = event.layer;
  });
  useEffect(() => {
    thisSelectedTool.current = selectedTool;
  }, [selectedTool]);
  const { create_item, update_item } = useItemService();
  /**
   * 
   * useEffect - mapRef?.current
   */
  useEffect(() => {
    if (mapRef?.current) {
      mapRef.current.on('click', event => {
        if (userCan('editItem')) {
          if (thisSelectedTool.current) {
            if (!mapRef.current.editTools?.drawing()) {
              switch (thisSelectedTool.current) {
                case 'tree':
                case 'marker':
                  create_item({
                    itName: '',
                    itParentId: thisActiveLayerId.current,
                    itType: thisSelectedTool.current,
                    itStatus: 'active',
                    itGeometry: [event.latlng.lat, event.latlng.lng]
                  });
                  break;
                case 'alignment':
                  currentDrawingLayer.current = mapRef.current.editTools.startPolyline(event.latlng, { item: { itId: '', itType: thisSelectedTool.current, itFeatures: { childrenCount: 3 } } });
                  setIsDrawing(true);
                  break;
                case 'line':
                  currentDrawingLayer.current = mapRef.current.editTools.startPolyline(event.latlng, { item: { itId: '', itType: thisSelectedTool.current, } });
                  setIsDrawing(true);
                  break;
                case 'polygon':
                  currentDrawingLayer.current = mapRef.current.editTools.startPolygon(event.latlng, { item: { itId: '', itType: thisSelectedTool.current, } });
                  setIsDrawing(true);
                  break;
                case 'circle':
                  currentDrawingLayer.current = mapRef.current.editTools.startCircle(event.latlng, { item: { itId: '', itType: thisSelectedTool.current, } });
                  setIsDrawing(true);
                  break;
                default:
              }
            }
          } else {
            return;
          }
        }
      })
      mapRef.current.on('keydown', event => {
        switch (event.originalEvent.keyCode) {
          case 27:
            try {
              if (mapRef.current?.editTools?.drawing()) {
                mapRef.current.editTools.stopDrawing();
                mapRef.current.removeLayer(currentDrawingLayer.current);
                window.setTimeout(() => { currentDrawingLayer.current = null }, 500)
                return;
              }
            } catch (e) {
            }
            break;
        }
      })
    }
  }, [mapRef?.current]);
  const finishDrawing = (how) => {
    setIsDrawing(false);
    switch (how) {
      case 'validate':
        if (currentDrawingLayer.current?.options) { // && mapRef.current.editTools?.drawing()
          const latlngs = currentDrawingLayer.current.getLatLngs();
          if (currentDrawingLayer.current.options.item.itId != '') {
            let itGeometry;
            if (Array.isArray(latlngs[0])) {
              itGeometry = latlngs[0].map(({ lat, lng }) => ({ lat, lng }));
            } else {
              itGeometry = latlngs.map(({ lat, lng }) => ({ lat, lng }));
            }
            const item = { ...currentDrawingLayer.current.options.item, itGeometry: itGeometry }
            update_item(item)
            currentDrawingLayer.current = null;
          } else {
            if (latlngs?.length > 1) {
              mapRef.current.editTools.commitDrawing();
            }
          }
        }
        break;
      case 'cancel':
        cancelDrawing();
        break;
    }
  }
  const cancelDrawing = () => {
    try {
      if (mapRef.current?.editTools?.drawing()) {
        mapRef.current.editTools.stopDrawing();
        mapRef.current.removeLayer(currentDrawingLayer.current);
        window.setTimeout(() => { currentDrawingLayer.current = null }, 500)
        return;
      }
    } catch (e) {
    }
  }
  return {
    isDrawing,
    finishDrawing,
  }
}
export default useMapService;
