// ProjectIcons.js
import { PiLineSegment, PiLineSegments, PiPolygon } from "react-icons/pi"; // PiLineSegments  ligne brisée
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { RiTreeLine, RiCircleLine } from "react-icons/ri";
import { MdOutlineQuestionMark, MdCheck, MdClose } from "react-icons/md";
import { BsLayers } from "react-icons/bs";
const ProjectIcons = ({ type, iconSize = 20, ...rest }) => {
  switch (type) {
    case 'tree':
      return <RiTreeLine size={iconSize} {...rest} />;
    case 'marker':
      return <LiaMapMarkerAltSolid size={iconSize} {...rest} />;
    case 'line':
      return <PiLineSegment size={iconSize} {...rest} />;
    case 'alignment':
      return (
        <div className='position-relative overflow-hidden'>
          <RiTreeLine size={iconSize} {...rest} style={{ top: '-2px', left: '-5px', width: `${iconSize / 1.1}`, }} />
          <PiLineSegment size={10} {...rest} className="position-absolute" style={{ top: '25%', right: '0px', width: `${iconSize / 1.3}`, opacity: '0.7' }} />
        </div>
      );
    case 'woodedarea':
      return (
        <div className='position-relative overflow-hidden'>
          <RiTreeLine size={iconSize} {...rest} style={{ top: '-2px', left: '-5px', width: `${iconSize / 1.1}`, }} />
          <PiPolygon size={10} {...rest} className="position-absolute" style={{ top: '25%', right: '0px', width: `${iconSize / 1.3}`, opacity: '0.7' }} />
        </div>
      );
    case 'polygon':
      return <PiPolygon size={iconSize} {...rest} />;
    case 'circle':
      return <RiCircleLine size={iconSize} {...rest} />;
    case 'layer':
      return <BsLayers size={iconSize} {...rest} />;
    case 'validate':
      return <MdCheck size={iconSize} {...rest} />;
    case 'cancel':
      return <MdClose size={iconSize} {...rest} />;
    default:
      return <MdOutlineQuestionMark size={iconSize} {...rest} />;
  }
};
export default ProjectIcons;