// src/components/homes/HomeDefault.js
import { useEffect, useState } from 'react';
import { debug } from './../../utils'
import { useNavigate } from 'react-router-dom';
import UserSignInModal from './../user/UserSignInModal';
import { useSelector } from 'react-redux';
import './homes.css';
const HomeDefault = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user.isSignedIn) {
      navigate('/home')
    }
  }, []);
  return (
    <>
      <UserSignInModal show={true} options={{ onSigned: '/home', close: false }} onClose={e => { }} onSuccess={e => { }} />
      {debug(user)}
    </>
  )
};
export default HomeDefault;
