import React, { useRef, useEffect, useState, useCallback } from 'react';
import { file, swalAlert } from 'utils';
import { MdBrush, MdEdit } from 'react-icons/md'; // Importation des icônes pour les outils
import { FaCircle } from 'react-icons/fa'; // Importation des icônes pour les outils
import { PiLineSegmentLight, PiEraserLight } from 'react-icons/pi';
import { TfiSave } from "react-icons/tfi";
import './drawing-canvas.css';
const DrawingCanvas = ({ src, mimeType, onChange }) => {
  const canvasRef = useRef(null);
  const drawingCanvasRef = useRef(null);
  const imgRef = useRef(new Image());
  const colorRef = useRef('#000000');
  const toolRef = useRef('');
  const thicknessRef = useRef('');
  const lastPointRef = useRef({ x: null, y: null });
  const [color, setColor] = useState('#FFFF00'); // État pour la couleur sélectionnée
  const [tool, setTool] = useState(''); // État pour l'outil sélectionné
  const thicknesses = [2, 7, 10];
  const [thickness, setThickness] = useState(thicknesses[1]); // État pour l'outil sélectionné

  useEffect(() => {

  }, [color]);
  useEffect(() => {
    colorRef.current = color;
    toolRef.current = tool;
    thicknessRef.current = thickness;
    lastPointRef.current = { x: null, y: null };
  }, [color, tool, thicknesses]);
  const adjustCanvasSize = useCallback(() => {
    if (!imgRef.current.complete) return;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const maxWidth = viewportWidth * 0.9;
    const maxHeight = viewportHeight * 0.9;
    const imgWidth = imgRef.current.width;
    const imgHeight = imgRef.current.height;
    const imgAspectRatio = imgWidth / imgHeight;
    const viewportAspectRatio = maxWidth / maxHeight;
    let finalWidth, finalHeight;
    if (imgAspectRatio > viewportAspectRatio) {
      finalWidth = maxWidth;
      finalHeight = maxWidth / imgAspectRatio;
    } else {
      finalHeight = maxHeight;
      finalWidth = maxHeight * imgAspectRatio;
    }
    const canvas = canvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    canvas.width = finalWidth;
    canvas.height = finalHeight;
    canvas.width = finalWidth;
    drawingCanvas.width = finalWidth;
    drawingCanvas.height = finalHeight;
    // canvas.getContext('2d').drawImage(imgRef.current, 0, 0, finalWidth, finalHeight);
  }, []);
  useEffect(() => {
    const canvas = canvasRef.current;
    const drawingCanvas = drawingCanvasRef.current;
    if (src !== '' && canvas) {
      const context = canvas.getContext('2d');
      const drawingContext = drawingCanvas.getContext('2d');
      let drawing = false;
      const getTouchPos = (canvasDom, touchEvent) => {
        const rect = canvasDom.getBoundingClientRect();
        return {
          x: touchEvent.touches[0].clientX - rect.left,
          y: touchEvent.touches[0].clientY - rect.top,
        };
      };
      const mouseEventToCoord = (e) => {
        const rect = drawingCanvas.getBoundingClientRect();
        return {
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        };
      };
      const startDrawing = (e) => {
        const { x, y } = e.touches ? getTouchPos(drawingCanvas, e) : mouseEventToCoord(e);
        switch (toolRef.current) {
          case 'brush':
            drawing = true;
            context.moveTo(x, y);
            draw(e);
          case 'eraser':
            drawing = true;
            context.moveTo(x, y);
            break;
          case 'line':
            lastPointRef.current = { x: x, y: y };

            break;
        }
      };
      const draw = (e) => {
        const { x, y } = e.touches ? getTouchPos(drawingCanvas, e) : mouseEventToCoord(e);
        switch (toolRef.current) {
          case 'brush':
            if (!drawing) return;

            context.lineWidth = thicknessRef.current;
            context.lineCap = 'round';
            context.strokeStyle = colorRef.current;
            context.globalCompositeOperation = 'source-over'
            context.lineTo(x, y);
            context.stroke();
            break;
          case 'eraser':
            if (!drawing) return;
            context.lineWidth = thicknessRef.current * 5;
            context.lineCap = 'round';
            context.globalCompositeOperation = "destination-out"; // Permet l'effacement
            context.beginPath(); // Important pour commencer un nouveau chemin d'effacement
            // Utilise moveTo seulement si tu as un point précédent valide
            if (lastPointRef.current.x !== null && lastPointRef.current.y !== null) {
              context.moveTo(lastPointRef.current.x, lastPointRef.current.y);
            }
            context.lineTo(x, y); // Continue le chemin d'effacement au nouveau point
            context.stroke(); // Applique l'effacement
            lastPointRef.current = { x, y }; // Met à jour le dernier point
            break;
          case 'line':
            if (lastPointRef.current.x) {
              drawingContext.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
              drawingContext.lineWidth = thicknessRef.current;
              drawingContext.beginPath();
              drawingContext.moveTo(lastPointRef.current.x, lastPointRef.current.y);
              drawingContext.strokeStyle = colorRef.current;
              drawingContext.lineTo(x, y);
              drawingContext.stroke();
            }

            break;
        }
      };
      const mouseUp = (e) => {
        switch (toolRef.current) {
          case 'brush':
            drawing = false;
            context.beginPath();
            break;
          case 'eraser':
            drawing = false;
            lastPointRef.current = { x: null, y: null };
            break;
          case 'line':
            if (lastPointRef.current.x) {

              const { x, y } = e.touches ? getTouchPos(drawingContext, e) : mouseEventToCoord(e);
              context.lineWidth = thicknessRef.current;
              context.beginPath();
              context.moveTo(lastPointRef.current.x, lastPointRef.current.y);
              context.strokeStyle = colorRef.current;
              context.lineTo(x, y);
              context.stroke();
              lastPointRef.current = { x: null, y: null };
              drawingContext.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
            }
            break;
        }
      }
      const stopDrawing = (e) => {
        lastPointRef.current = { x: null, y: null };
        drawing = false;
        context.beginPath();
      };
      imgRef.current.onload = adjustCanvasSize;
      imgRef.current.src = src;
      drawingCanvas.addEventListener('mousedown', startDrawing);
      drawingCanvas.addEventListener('mousemove', draw);
      drawingCanvas.addEventListener('mouseup', mouseUp);
      drawingCanvas.addEventListener('mouseout', stopDrawing);
      drawingCanvas.addEventListener('touchstart', startDrawing);
      drawingCanvas.addEventListener('touchmove', draw);
      drawingCanvas.addEventListener('touchend', mouseUp);
      return () => {
        drawingCanvas.removeEventListener('mousedown', startDrawing);
        drawingCanvas.removeEventListener('mousemove', draw);
        drawingCanvas.removeEventListener('mouseup', mouseUp);
        drawingCanvas.removeEventListener('mouseout', stopDrawing);
        drawingCanvas.removeEventListener('touchstart', startDrawing);
        drawingCanvas.removeEventListener('touchmove', draw);
        drawingCanvas.removeEventListener('touchend', mouseUp);
      };
    }
  }, [adjustCanvasSize]);

  const handleSave = () => {
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = canvasRef.current.width; // Assure-toi que ces dimensions sont correctes
    tempCanvas.height = canvasRef.current.height;
    // Dessine l'image de fond
    if (imgRef.current.src) {
      tempCtx.drawImage(imgRef.current, 0, 0, tempCanvas.width, tempCanvas.height);
    }
    tempCtx.drawImage(canvasRef.current, 0, 0);
    tempCtx.drawImage(drawingCanvasRef.current, 0, 0);
    const imageURL = tempCanvas.toDataURL(mimeType);
    onChange(imageURL);
  }

  return (
    <div id="DrawingCanvas" className=''>
      <div className="toolbar">
        <MdBrush onClick={() => setTool('brush')} className={`tool ${tool == 'brush' ? 'selected' : ''}`} />
        <PiLineSegmentLight onClick={() => setTool('line')} className={`tool ${tool == 'line' ? 'selected' : ''}`} />
        <PiEraserLight onClick={() => setTool('eraser')} className={`tool ${tool == 'eraser' ? 'selected' : ''}`} />
        <span className='divider'></span>
        <div className='thickness'>
          {thicknesses.map(t => (
            <span onClick={() => setThickness(t)} className={`tool ${thickness == t ? 'selected' : ''}`}>
              <FaCircle />
            </span>
          ))}


        </div>
        <span className='divider'></span>
        {/* Sélecteur de couleur */}
        <input type="color" className=" color-picker" value={color} onChange={(e) => setColor(e.target.value)} />
        <TfiSave onClick={handleSave} className={`tool`} />
      </div>
      <div className='position-relative'>
        <img ref={imgRef} />
        <canvas ref={canvasRef} className='position-relative' />
        <canvas ref={drawingCanvasRef} id="canvas-over"></canvas>
      </div>
    </div >
  );
};
export default DrawingCanvas;
