import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { debug } from './../../../utils';
import API from './../../../api';
import CitySelector from './../../helpers/CitySelector/CitySelector';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetails } from './../../../redux/userSlice';
import CustomAlert from './../../helpers/CustomAlert/CustomAlert';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { HtmlElement } from './../../helpers/HtmlElements/HtmlElements';
const ModalCreateProject = ({ show, handleClose = null, onCreated }) => {
  const user = useSelector((state) => state.user);
  const {
    userCan,
  } = useProjectMapContext();
  const dispatch = useDispatch();
  const [project, setProject] = useState({ projName: '', projSummary: '', projCityCode: '', city: {} })
  const [error, setError] = useState({})
  const handleChange = (event) => {
    setProject({ ...project, [event.target.name]: event.target.value });
  }
  const handleCitySelected = (value) => {
    setProject({ ...project, projCityCode: value.cityCode, projGeometry: value.citLatLong.split(','), city: value });
  }
  const handleCreate = async () => {
    try {
      setError();
      if (project.projName == '') {
        throw ('Le nom du projet est requis.')
      }
      if (project.projCityCode == '') {
        throw ('Le nom de la commune  est requis.')
      }
      const response = await API.post('/project', project);
      dispatch(fetchUserDetails());
      onCreated(response.data);
    } catch (error) {
      setError(error)
    }
  }
  return (
    userCan('createProject') &&
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Créer un projet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className='mb-5' >
          <div className="mb-3">
            <label className="form-group-label" required>Nom du projet : </label>
            <input type="text" className="form-control" name="projName" value={project.projName} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-group-label">Description du projet : </label>
            <input type="text" className="form-control" name="projSummary" value={project.projSummary} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-group-label" required>Commune : </label>
            <CitySelector onSelected={handleCitySelected} city={project.city} />
          </div>
          <div className="mb-3">
            <label className="form-group-label" >Adresse : </label>
            <HtmlElement.formGroup size='' name='projAddress' value={project.projAddress} onChange={handleChange} />
          </div>
          <CustomAlert input={error} onClose={e => setError(null)} />
        </form>
        <Button variant="primary" onClick={handleCreate}>
          Créer le projet
        </Button>
        {debug(project, 0)}
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
};
export default ModalCreateProject;
