//MarkerFrom.js
import React, { useEffect, useState, useRef } from 'react';
import { useProjectMapContext } from './../../../hooks/useProjectMapContext';
import useItemsService from './../../../hooks/useItemsService';
import { HtmlElement } from '../../../../helpers/HtmlElements/HtmlElements';
import { debug } from '../../../../../utils';
const LayerForm = ({ item = {} }) => {
  const { setItemSelected } = useProjectMapContext();
  const { save_item, update_item } = useItemsService();
  const [itemCopy, setItemCopy] = useState({}); // Correction ici
  useEffect(() => {
    setItemCopy({ ...item });

  }, [item]);
  const onChange = (event) => {
    const { name, value } = event.target;
    const features = { ...itemCopy.itFeatures };
    const payload = { ...itemCopy }
    if (name.match(/itFeatures/)) {
      features[name.replace('itFeatures.', '')] = value;
      payload.itFeatures = { ...features };
    } else {
      payload[name] = value;
    }
    setItemCopy(payload);

  }
  const handleClick = () => {
    itemCopy.itId && itemCopy.itId !== '' ? update_item(itemCopy) : save_item(itemCopy);
    setItemSelected(null);
  }
  const colorOptions = [
    { value: "", label: "Aucune" }, // Noir
    { value: "#3d5e33", label: "Par défault" }, // Noir
    { value: "#000000", label: "Noir" }, // Noir
    { value: "#800000", label: "Rouge" }, // Rouge brûlé
    { value: "#008000", label: "Vert" }, // Vert forêt
    { value: "#808000", label: "Jaune" }, // Jaune olive
    { value: "#000080", label: "Bleu" }, // Bleu marine
    { value: "#800080", label: "Violet" }, // Violet prune
    { value: "#008080", label: "Cyan" }, // Cyan foncé
    { value: "#808080", label: "Gris" } // Gris ardoise
  ];

  const opacitiesOptions = [
    { value: 0, label: "Transparent" },
    { value: 0.1, label: "10%" },
    { value: 0.2, label: "20%" },
    { value: 0.3, label: "30%" },
    { value: 0.4, label: "40%" },
    { value: 0.5, label: "50%" },
    { value: 0.6, label: "60%" },
    { value: 0.7, label: "70%" },
    { value: 0.8, label: "80%" },
    { value: 0.9, label: "90%" },
    { value: 1, label: "100%" }
  ];
  return (
    <div id="LayerForm">
      <HtmlElement.formGroup value={itemCopy.itName} name='itName' label='Nom :' onChange={onChange} required={true} className='mb-3' />
      <div className='row'>
        <div className='col-6'><HtmlElement.formGroup type='select' options={colorOptions} value={itemCopy.itFeatures?.color} name='itFeatures.color' label='Couleur : ' onChange={onChange} className='mb-3' /></div>
        <div className='col-6'><HtmlElement.formGroup type='select' options={opacitiesOptions} value={itemCopy.itFeatures?.opacity} name='itFeatures.opacity' label='Opacité : ' onChange={onChange} className='mb-3' /></div>
      </div>
      <HtmlElement.formGroup type='textarea' value={itemCopy.itFeatures?.memo} name='itFeatures.memo' label='Note : ' onChange={onChange} className='mb-5' />
      <HtmlElement.submit onClick={handleClick} />
      {debug(itemCopy)}
    </div >
  );
};
export default LayerForm;

